<template>
  <div class="teacher-details-area section-padding">
    <div class="container">

      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="teacher-details-image">
            <img :src="profilePicture" alt="" />
            <div class="social-links">
              <a href="#"><i class="fa fa-facebook"></i></a>
              <a href="#"><i class="fa fa-google-plus"></i></a>
              <a href="#"><i class="fa fa-twitter"></i></a>
              <a href="#"><i class="fa fa-instagram"></i></a>
            </div>
          </div>
          <div class="teacher-details-info">
            <h4>{{personalDetails.name}}</h4>
            <span>{{personalDetails.title}}, {{ personalDetails.college}}</span>
            <div class="teacher-info-text">
                <span
                ><i class="fa fa-phone"></i>Phone: {{
contactDetails.phone
                  }}</span
                >
              <span
              ><i class="fa fa-envelope"></i>Email:
                  {{ contactDetails.email}}</span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="teacher-about-info">
            <div class="single-title">
              <h3>About</h3>
            </div>
            <div class="teacher-info-text">
                <span
                ><i class="fa fa-graduation-cap"></i>Degree: {{
                   degree
                  }}</span
                >
              <span
              ><i class="fa fa-star"></i>Experience: {{
                  experience
                 }} years</span
              >
              <span
              ><i class="fa fa-heart"></i>Hobbies:
                <span v-for="hobby in hobbies">
                {{hobby}},

              </span></span
              >
              <span
              ><i class="fa fa-edit"></i>My Courses:
                 <span v-for="course in courses">
                {{course.title}},

              </span>
              </span
              >

            </div>
            <p>
              {{
                description
              }}
            </p>

          </div>
          <div class="schedule-skill-wrapper ">
            <div class="row">
              <div class="col-md-6">
                <div class="single-title">
                  <h3>My Schedule</h3>
                </div>
                <div class="schedule-text">
                    <span
                    >Monday <span class="schedule-time">{{ schedule.monday }}</span></span
                    >
                  <span
                  >Tuesday
                      <span class="schedule-time">{{ schedule.tuesday }}</span></span
                  >
                  <span
                  >Wednesday
                      <span class="schedule-time">{{ schedule.wednesday }}</span></span
                  >
                  <span
                  >Thursday
                      <span class="schedule-time">{{ schedule.thursday }}</span></span
                  >
                  <span
                  >Friday <span class="schedule-time">{{ schedule.friday }}</span></span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="single-title">
                  <h3>My Skills</h3>
                </div>
                <div class="skill-bars orange ">
                  <div v-for="skill in skills" class="skill-bar-item pill float-end">
                    <span>{{ skill}}</span>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script >
export default {
  name: 'teacher',
  data() {
    return {
      teacher: null,
degree :null,
experience :null,
hobbies :[],
courses :[],
schedule :[],
skills :[],
      description : "",
      profilePicture : "",

personalDetails: {},
      contactDetails : {},
      teachers:[

        {
          "Id" : 1,
          "degree": "Bachelor's Degree in Elementary Education",
          "experience": 5,
          "hobbies": ["reading", "hiking", "painting"],
          "myCourses": [
            {
              "title": "Grade 1 English",
              "description": "A course on English language and literature for grade 1 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a passionate and dedicated teacher with 5 years of experience.",
          "contactDetails": {
            "phone": "+1234567890",
            "email": "john.doe@example.com"
          },
          "skills": ["Microsoft Office", "Google Classroom",
"communication", "teamwork", "problem-solving"],

          "schedule": {
            "monday": "9:00 AM - 3:00 PM",
            "tuesday": "10:00 AM - 11:00 AM",
            "wednesday": "9:00 AM - 4:00 PM",
            "thursday": "11:00 AM - 12:00 PM",
            "friday": "10:00 AM - 11:00 AM"
          },
          personalDetails: {
            name: "John Doe",
            title: "Grade 1 Teacher",
            college: "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.48.51.jpeg'),          }
        },



        {
          "Id" : 2,
          "degree": "Bachelor's Degree in Mathematics",
          "experience": 3,
          "hobbies": ["playing chess", "reading", "coding"],
          "myCourses": [
            {
              "title": "Grade 2 Mathematics",
              "description": "A course on mathematics for grade 2 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a math enthusiast with 3 years of teaching experience.",
          "contactDetails": {
            "phone": "+9876543210",
            "email": "jane.doe@example.com"
          },
          "skills": ["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"],
          "schedule": {
            "monday": "10:00 AM - 4:00 PM",
            "tuesday": "9:00 AM - 10:00 AM",
            "wednesday": "11:00 AM - 3:00 PM",
            "thursday": "10:00 AM - 11:00 AM",
            "friday": "9:00 AM - 10:00 AM"
          },
          "personalDetails": {
            "name": "Jane Doe",
            "title": "Grade 2 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.16.jpeg'),
          }
        },



        {
          "Id" : 3,
          "degree": "Bachelor's Degree in Science",
          "experience": 6,
          "hobbies": ["gardening", "cooking", "reading"],
          "myCourses": [
            {
              "title": "Grade 3 Science",
              "description": "A course on science for grade 3 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a passionate science teacher with 6 years of experience.",
          "contactDetails": {
            "phone": "+5555555555",
            "email": "bob.smith@example.com"
          },
          "skills":  ["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"]
          ,
          "schedule": {
            "monday": "11:00 AM -3:00 PM",
            "tuesday": "10:00 AM - 11:00 AM",
            "wednesday": "9:00 AM - 4:00 PM",
            "thursday": "11:00 AM - 12:00 PM",
            "friday": "10:00 AM - 11:00 AM"
          },
          "personalDetails": {
            "name": "Bob Smith",
            "title": "Grade 3 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.44.jpeg'),
          }
        },


        {
          "Id" : 4,
          "degree": "Bachelor's Degree in Language Arts",
          "experience": 8,
          "hobbies": ["writing", "reading", "traveling"],
          "myCourses": [
            {
              "title": "Grade 4 English",
              "description": "A course on English language and literature for grade 4 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a dedicated and passionate language arts teacher with 8 years of experience.",
          "contactDetails": {
            "phone": "+6666666666",
            "email": "alice.johnson@example.com"
          },
          "skills":["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"]
          ,
          "schedule": {
            "monday": "9:00 AM - 3:00 PM",
            "tuesday": "11:00 AM - 12:00 PM",
            "wednesday": "10:00 AM - 4:00 PM",
            "thursday": "9:00 AM - 10:00 AM",
            "friday": "11:00 AM - 12:00 PM"
          },
          personalDetails: {
            "name": "Alice Johnson",
            "title": "Grade 4 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.50.09.jpeg'),
          }
        }
      ]
    }

  },
 mounted() {
   this.teacherId = this.$route.params.id,
    console.log(this.teacherId)
   let teacher = this.teachers[this.teacherId-1]
   this.personalDetails = teacher.personalDetails
   this.contactDetails = teacher.contactDetails
   this.degree = teacher.degree
   this.experience =teacher.experience
   this.hobbies = teacher.hobbies
   this.courses = teacher.myCourses
   this.description = teacher.description
   this.schedule = teacher.schedule
   this.skills = teacher.skills
   this.profilePicture = teacher.personalDetails.profilePicture
    console.log(this.contactDetails,'teacher')
    console.log(teacher,'teacher')
  }
}
</script>
<style scoped>

</style>