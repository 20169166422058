<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="testimonial-image-text-wrapper" v-for="(slide, index) in slides" :key="slide.Id" v-show="index === slideIndex">
        <div class="testimonial-image-slider d-flex justify-content-center align-content-center ">
          <div class="sin-testiImage">
            <div class="teacher-image-carousel ">
              <img  :src="slide.profilePicture" alt="" />
            </div>
          </div>
        </div>
        <div class="testimonial-text-slider">
          <div class="row mt-1">
            <div class="sin-testiText">

              <h2 class=" d-flex justify-content-center">{{ slide.name }}</h2>
              <span class="language d-flex justify-content-center">{{ slide.title }}</span>



            </div>
            <div class="col-lg-6">
              <div class="sin-testiText">

                <div class="teacher-info-container">
                  <p>
                    <i class="fa fa-graduation-cap"></i>Degree: {{ slide.degree }}
                  </p>
                  <p>
                    <i class="fa fa-star"></i>Experience: {{ slide.experience }} Years
                  </p>
                </div>
                <div class="teacher-links">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="sin-testiText">
                <div class="teacher-info-container">
                  <p >{{ slide.description }}</p>
                  <p class="text-white" > rrr</p>

                </div>

                <div class="view-profile">
                  <router-link to="/teacher" class="button-default">View Profile</router-link>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div style="text-align:center">
        <span
            class="dot"
            v-for="(slide, index) in slides"
            :key="index"
            @click="currentSlide(index)"
            :class="{ 'active': index === slideIndex }"
        ></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slideIndex: 0,
      slides: [
        {
          Id: 1,
          degree: "Bachelor's Degree in Elementary Education",
          experience: 5,
          description: "I am a passionate and dedicated teacher with 5 years of experience.",
          name: "John Doe",
          title: "Grade 1 Teacher",
          profilePicture: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.48.51.jpeg'),
        },
        {
          Id: 2,
          degree: "Bachelor's Degree in Mathematics",
          experience: 3,
          description: "I am a math enthusiast with 3 years of teaching experience.",
          name: "Jane Doe",
          title: "Grade 2 Teacher",
          profilePicture: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.16.jpeg'),
        },
        {
          Id: 3,
          degree: "Bachelor's Degree in Natural Science",
          experience: 6,
          description: "I am a passionate science teacher with 6 years of experience.",
          name: "Bob Smith",
          title: "Grade 3 Teacher",
          profilePicture: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.44.jpeg'),
        },
        {
          Id: 4,
          degree: "Bachelor's Degree in Language Arts",
          experience: 8,
          description: "I am a dedicated and passionate language arts teacher with 8 years of experience.",
          name: "Alice Johnson",
          title: "Grade 4 Teacher",
          profilePicture: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.50.09.jpeg'),
        }
      ],
    };
  },
  methods: {
    nextSlide() {
      this.slideIndex = (this.slideIndex + 1) % this.slides.length;
    },
    previousSlide() {
      this.slideIndex = (this.slideIndex - 1 + this.slides.length) % this.slides.length;
    },
    currentSlide(index) {
      this.slideIndex = index;
    }
  },
  mounted() {
    setInterval(this.nextSlide, 7000);
  },
};
</script>

<style scoped>

body{
  margin: 0;
}



/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 60%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;

}

/* Position the "next button" to the right */
.next {
  right: 10px;
  border-radius: 3px 0 0 3px;
}
.prev {
  left: 10px;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
margin-top: 40px;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
.caption{
  background: #1f2836;
}



/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 1000px) {
  .mySlides img {
    width: 100%;
    height: 300px;
    filter: blur(5px); /* Add this line to blur the image */
  }

  .caption {
    background: #1f2836;
    width: 100%; /* Add this line to make the caption take up the full width */
  }

  .caption .text {
    font-size: 12px; /* Adjust the font size as needed */
  }

  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 75%;
    width: auto;
    padding: 8px; /* Reduce the padding to make the buttons smaller */
    color: black;
    font-weight: bold;
    font-size: 11px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
  }
}
</style>
<!--<template>-->
<!--  <section class="hero">-->
<!--    <div class="hero-content">-->
<!--      <h1>Elevate Your Potential</h1>-->
<!--      <h2>Embark on an Exceptional Journey at Greenfield University</h2>-->
<!--      <p>Greenfield University offers a transformative educational experience that empowers you to unlock your true potential. Immerse yourself in a vibrant community that nurtures academic excellence, fosters personal growth, and equips you with the skills to thrive in your future.</p>-->
<!--      <p>Take the first step towards a brighter tomorrow. Discover the limitless possibilities that await you at Greenfield University</p>-->
<!--    </div>-->
<!--    <div class="hero-slider">-->
<!--      <div class="slide" v-for="(slide, index) in slides" :key="index" :class="{ 'active': index === currentIndex }">-->
<!--        <img :src="slide.image" :alt="slide.alt">-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      slides: [-->
<!--        {-->
<!--          image: require('@/assets/images/school2.jpg'),-->
<!--          title: 'Classes',-->
<!--          caption: 'Top notch classes',-->
<!--          alt: 'school2'-->
<!--        },-->
<!--        {-->
<!--          image: require('@/assets/images/school1.jpg'),-->
<!--          title: 'Environment',-->
<!--          caption: 'World-class learning environment',-->
<!--          alt: 'image2'-->
<!--        },-->
<!--        {-->
<!--          image: require('@/assets/images/vanTrans.jpg'),-->
<!--          title: 'Transport',-->
<!--          caption: 'We offer transport facilities',-->
<!--          alt: 'image3'-->
<!--        },-->
<!--        {-->
<!--          image: require('@/assets/images/students.jpg'),-->
<!--          title: 'Students',-->
<!--          caption: 'We create an environment where our students can be raised to the best version of themselves',-->
<!--          alt: 'image4'-->
<!--        },-->
<!--        {-->
<!--          image: require('@/assets/images/corridor.jpg'),-->
<!--          title: 'Staff',-->
<!--          caption: 'Reception',-->
<!--          alt: 'image5'-->
<!--        }-->
<!--      ],-->
<!--      currentIndex: 0-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    startSlider() {-->
<!--      setInterval(this.showNextSlide, 5000);-->
<!--    },-->
<!--    showNextSlide() {-->
<!--      this.currentIndex = (this.currentIndex + 1) % this.slides.length;-->
<!--    }-->
<!--  },-->
<!--  mounted() {-->
<!--    this.startSlider();-->
<!--  }-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--.hero {-->
<!--  display: flex;-->
<!--  justify-content: space-between;-->
<!--  align-items: center;-->
<!--  height: 80vh;-->
<!--  background-color: #f5f5f5;-->
<!--  padding: 0 2rem;-->
<!--}-->
<!--.hero-slider .slide {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  opacity: 0;-->

<!--}-->

<!--.hero-slider .slide.active {-->
<!--  opacity: 1;-->
<!--}-->
<!--.hero-content {-->
<!--  max-width: 600px;-->
<!--  text-align: left;-->
<!--}-->

<!--.hero-content h1 {-->
<!--  font-size: 3rem;-->
<!--  font-weight: bold;-->
<!--  margin-bottom: 1rem;-->
<!--}-->

<!--.hero-content h2 {-->
<!--  font-size: 2rem;-->
<!--  font-weight: bold;-->
<!--  margin-bottom: 1rem;-->
<!--}-->

<!--.hero-content p {-->
<!--  font-size: 1.2rem;-->
<!--  line-height: 1.5;-->
<!--  margin-bottom: 1rem;-->
<!--}-->

<!--.hero-slider {-->
<!--  width: 50%;-->
<!--  height: 100%;-->
<!--  overflow: hidden;-->
<!--}-->

<!--.hero-slider .slide {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  animation: slideShow 10s infinite linear;-->
<!--}-->

<!--.hero-slider .slide img {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  object-fit: cover;-->
<!--}-->

<!--@keyframes slideShow {-->
<!--  0% {-->
<!--    opacity: 0;-->
<!--  }-->
<!--  20% {-->
<!--    opacity: 1;-->
<!--  }-->
<!--  80% {-->
<!--    opacity: 1;-->
<!--  }-->
<!--  100% {-->
<!--    opacity: 0;-->
<!--  }-->
<!--}-->
<!--</style>-->

