<template>
<div class="wrapper">
  <BannerSlider/>
  <div class="activity-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6 activity">
          <div class="single-activity">
            <div class="single-activity-icon">
              <i class="fa fa-soccer-ball-o"></i>
            </div>
            <h4>Sports & Games</h4>
            <p>
              Join our vibrant sports community! From soccer to basketball,
              our teams foster teamwork, discipline, and school spirit.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 activity">
          <div class="single-activity">
            <div class="single-activity-icon">
              <i class="fa fa-book"></i>
            </div>
            <h4>Study Groups</h4>
            <p>
              Collaborate with peers in our study groups to enhance learning
              and prepare for exams. Together, we achieve more!
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 activity">
          <div class="single-activity mrg-res-top-md">
            <div class="single-activity-icon">
              <i class="fa fa-users"></i>
            </div>
            <h4>Clubs & Organizations</h4>
            <p>
              Explore your interests by joining various clubs—from debate
              to robotics. Get involved and make lasting friendships!
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 activity">
          <div class="single-activity mrg-res-top-md">
            <div class="single-activity-icon">
              <i class="fa fa-graduation-cap"></i>
            </div>
            <h4>College Prep</h4>
            <p>
              Our college preparation programs equip students with the
              tools and guidance needed for successful applications and
              future studies.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="blog-area ">
      <div class="container">
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3>Our Blog</h3>
                <p>Latest news &amp; event of our schools</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div v-for="(blog, index) in blogs" :key="index" class="col-lg-3">
            <div class="single-blog-item overlay-hover">
              <div class="single-blog-image">
                <div class="overlay-effect">
                  <router-link :to="`EventDetails/${blog.id}`">
                    <img :src="blog.image" alt="" height="210"/>
                    <span class="class-date">{{ blog.classDate }}</span>
                  </router-link>
                </div>
              </div>
              <div class="single-blog-text">
                <h4>
                  <router-link :to="`EventDetails/${blog.id}`">{{ getLimitedTitle(blog.title) }}</router-link>
                </h4>
                <div class="blog-date">
                  <span><i class="fa fa-calendar"></i>{{ blog.date }}</span>
                </div>
                <p>{{ blog.description }}</p>
                <router-link :to="`EventDetails/${blog.id}`">Read more.</router-link>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>



    <div class="row mt-2">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3>OUR CURRICULUM</h3>
              <p>zimsec , cambridge &amp; extra curriculum</p>
            </div>
          </div>
        </div>

      <div class="col-md-12  ">
        <div class="row d-flex justify-content-center
        align-content-center">
          <div class="col-md-6">
            <div class="popular-course">
              <p class="container text-center">
                Our diverse curriculum offers a wide range of subjects, catering to various academic interests,
                including sciences, technical subjects, technology, arts, and commerce. Our curriculum is designed to
                foster critical thinking and provide a comprehensive understanding of each field, empowering you to
                pursue your passions and academic goals.
              </p>
              <p class="container text-center">
                At Southmews, we provide comprehensive preparation for
                <span class="exam-boards">ZIMSEC</span> and
                <span class="exam-boards">Cambridge International Examinations</span>, ensuring students are confident
                and prepared for both local and international assessments. Their dedicated instructors guide students
                through comprehensive exam techniques.
              </p>
              <div class="logos mb-1">
                <div class="row d-flex justify-content-center align-content-center">
                  <div class="col-lg-3 col-3">
                    <img src="../assets/images/Zimsec-Logo.png" alt="" height="80px">
                  </div>
                  <div class="col-lg-3 col-3">
                    <img src="../assets/images/Cambridge-International-Exams-logo.png" alt="" height="80px">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 img ">
            <img src="../assets/images/student-exam.jpg" alt=""
                 width="90%" height="75%">
          </div>

        </div>

      </div>

      <div class="col-md-12">
        <div class="row d-flex ">
          <div class="col-lg-4">
            <div class="subject d-flex flex-column">
              <div class="single-title">
                <h3>Fields of Study</h3>
              </div>

              <div class="subject-box ">
                <h2>Subjects And Courses</h2>
                <i class="fa fa-book"></i>
                <h3 class="button-default">Commercials, Arts, Sciences</h3>
                <div class="subject-description">

                  <p>Our school offers a diverse range of subjects spanning the humanities, sciences, arts, languages,
                    and physical education. This well-rounded curriculum is designed to provide students with a
                    comprehensive educational experience and prepare them for success in their future academic and
                    professional pursuits.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="subject d-flex flex-column">
              <div class="single-title">
                <h3>Non-Academics</h3>
              </div>

              <div class="subject-box">
                <h2> Extracurricular Activities</h2>
                <i class="fa fa-soccer-ball-o"></i>
                <h3 class="button-default">Engage in various activities beyond academics.</h3>

                <div class="subject-description">

                  <p>These extracurricular activities provide students with opportunities to explore their interests,
                    develop new skills, build leadership experience, and engage with the broader school and local
                    community. The diverse range of offerings caters to students with varying passions and talents,
                    encouraging them to pursue well-rounded personal growth and enrichment beyond the classroom.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="subject d-flex flex-column">
              <div class="single-title">
                <h3>Examination Board</h3>
              </div>

              <div class="subject-box">
                <h2> Exam Options</h2>
                <i class="fa fa-check-circle"></i>
                <h3 class="button-default">Choose your examination pathway.</h3>
                <div class="subject-description">
                  <p>Our school offers exams from both ZIMSEC and Cambridge International Examinations. This dual option
                    allows students to choose the pathway that suits their academic goals and future aspirations.</p>

                  <p>ZIMSEC caters to domestic education, while Cambridge provides globally recognized credentials. This
                    flexible framework supports diverse student needs and aspirations.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">

            <router-link class="button-default" to="/SubjectsAndCourses">
              View All Courses
            </router-link>

        </div>
      </div>

    </div>
    <div class="teachers-area mt-3">
        <div class="container">
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="section-title-wrapper">
                <div class="section-title">
                  <h3>Our Teachers</h3>
                  <p>We are glad to introduce our professional staff</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-6">
              <div class="our-teacher">
                <div class="row">
                  <div class="col-md-12">
                    <div class="single-title">
                      <h3>Meet Our Teachers</h3>
                    </div>

                    <p class="text-center">
                      Discover our outstanding faculty, comprised of knowledgeable
                      experts who are committed to fostering a love for learning and
                      empowering students to reach their full potential. Experience the
                      transformative impact of our dedicated teachers and join our
                      educational community today.
                    </p>
                    <p class="text-center">
                      In addition to our exceptional faculty, we offer a range of
                      specialized instructors and industry professionals who bring
                      real-world expertise to the classroom. Expand your horizons with
                      our extended network of educators and enhance your learning
                      experience with practical insights and valuable connections.
                    </p>
                  </div>
                  <div class="col-md-12">
                    <div class="row  d-flex link justify-content-center align-content-center">
                      <div class="col-md-4 col-5 ">
                        <router-link class=" button-default " to="/careers"> Career with us</router-link>
                      </div>
                      <div class="col-md-4 col-5 ">
                        <router-link class="button-default " to="/Teachers"> Our Teachers</router-link>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div class="col-md-6 ">

                <slider/>





            </div>
          </div>


        </div>
      </div>




  </div>
  <div class="register-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-7 col-12">
          <div class="register-info">
            <h4>ENROLL IN OUR EXCITING <span>EDUCATIONAL PROGRAMS</span></h4>
            <h1>REGISTER NOW</h1>
            <p>Join our vibrant community of learners and unlock your potential with our diverse range of courses! <br>
            Whether you're looking to excel in academics, explore new interests, or develop valuable skills, we have something for everyone.
            Don't miss out on the opportunity to shape your future—spaces are limited!</p>
          </div>
        </div>
        <div class="col-lg-7 col-md-5 col-12">
                <div class="account-form">
                  <div class="register-title">
                    <i class="fa fa-lock"></i>
                    <h4>Register your child Now !</h4>
                  </div>
                  <form class="" @submit.prevent="submitForm()">
                    <div class="row ">
                      <div class="col-md-12">


                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label" for="First_Name">First Name:</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  id="First_Name"
                                  name="First_Name"
                                  maxlength="30"

                                  v-model="firstName"
                              />
                              <div class=" showError">{{ firstNameError }}</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label" for="Last_Name">Last Name:</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  id="Last_Name"
                                  name="Last_Name"
                                  maxlength="30"

                                  v-model="lastName"
                              />
                              <div class=" showError">{{ lastNameError }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label" for="Date_of_Birth">Date of Birth:</label>
                              <input
                                  type="date"
                                  class="form-control"
                                  id="Date_of_Birth"
                                  name="Date_of_Birth"

                                  v-model="dateOfBirth"
                              />
                              <div class=" showError">{{ dateOfBirthError }}</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label" for="Gender">Gender:</label>
                              <select class="form-control" id="Gender" name="Gender" v-model="gender">
                                <option value="" selected disabled>Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                              </select>
                              <div class=" showError">{{ genderError }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label" for="City">City:</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  id="City"
                                  name="City"
                                  maxlength="30"

                                  v-model="city"
                              />
                              <div class=" showError">{{ cityError }}</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label" for="Country">Country:</label>
                              <select class="form-control" id="Country" name="Country" v-model="country">
                                <option value="">Select country</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                                <option value="International">International</option>
                              </select>
                              <div class=" showError">{{ countryError }}</div>
                            </div>
                          </div>
                        </div>

                      </div>


                    </div>
                    <div class="row ">
                      <div class="col-md-12">


                        <div class="row">
                          <div class="col-md-6">

                            <div class="form-group">
                              <label class="form-label" for="Email_Id">Email:</label>
                              <input
                                  type="email"
                                  class="form-control"
                                  id="Email_Id"
                                  name="Email_Id"

                                  v-model="emailId"
                              />
                              <div class=" showError">{{ emailIdError }}</div>
                            </div>
                          </div>
                          <div class="col-md-6">

                            <div class="form-group">
                              <label class="form-label" for="Mobile_Number">Mobile Number:</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  id="Mobile_Number"
                                  name="Mobile_Number"
                                  maxlength="10"

                                  v-model="mobileNumber"
                              />
                              <div class=" showError">{{ mobileNumberError }}</div>
                            </div>
                          </div>
                        </div>



                      </div>

                      <div class="form-group center-align mt-3 mb-1v">
                        <button class="button-default" type="submit" >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>




        </div>
      </div>
    </div>
  </div>
</div>









</template>
<script>


import BannerSlider from "@/components/BannerSlider.vue";
import EventsCalender from "@/components/EventsCalender.vue";
import Imageslide from "@/components/teachersSlide.vue";
import slider from "@/components/teachersSlide.vue";


export default {
  components: {
    Imageslide,
    slider,
    EventsCalender,
    BannerSlider,


  },
  data() {
    return {
      teachers: [
        {
          teacherId: 1,
          lastName: "Svuure",
          gender: "Female",
          image: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.48.51.jpeg'),
          designation: "Grade 3 Teacher",

        },
        {
          teacherId: 2,

          lastName: "Chikanda",
          gender: "Female",
          image: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.16.jpeg'),
          designation: "ECD A  and TIC Teacher",

        },
        {
          teacherId: 3,
          lastName: "Mharidzo",
          gender: "Female",
          image: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.44.jpeg'),
          designation: "ECD B Teacher",

        },
        {
          teacherId: 4,
          lastName: "Gwerengwengwe",
          gender: "Female",
          image: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.50.09.jpeg'),
          designation: "Grade 1 Teacher Teacher",

        },
      ],
      teachersRand: [],

      loading: false,
      error: "",


      items: [
        {
          id: 1,
          text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          id: 2,
          text: 'Desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
        },
        {
          id: 3,
          text: 'Many desktop publishing packages and web page editors now use',
        },
        {
          id: 4,
          text: 'Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
        },
      ],
      blogs: [
        {
          id: 1,
          title: "New Friends Everyday at Kiddie",
          date: "08 Apr, 2016",
          description: "The concept of the activity room is about 'Learning', through play, in a totally different environment. The perfect class for your child with the best.",
          image: require("../assets/images/IMG-20241009-WA0054.jpg"),
          link: "blog-details.html",
          classDate: "Apr 21 2016"
        },
        {
          id: 2,
          title: "Swimming Lessons at New Pool",
          date: "27 Jan, 2014",
          description: "The concept of the activity room is about 'Learning', through play, in a totally different environment. The perfect class for your child with the best.",
          image: require("../assets/images/IMG-20241009-WA0069.jpg"),
          link: "blog-details.html",
          classDate: "Oct 21 2016"
        },
        {
          id: 3,
          title: "Play is Our Brain’s Favorite Way of",
          date: "30 Feb, 2015",
          description: "The concept of the activity room is about 'Learning', through play, in a totally different environment. The perfect class for your child with the best.",
          image: require("../assets/images/IMG-20241009-WA0058.jpg"),
          link: "blog-details.html",
          classDate: "Nov 21 2016"
        },
        {
          id: 4,
          title: "New Friends Everyday at Kiddie",
          date: "08 Apr, 2016",
          description: "The concept of the activity room is about 'Learning', through play, in a totally different environment. The perfect class for your child with the best.",
          image: require("../assets/images/IMG-20241009-WA0004.jpg"),
          link: "blog-details.html",
          classDate: "Dec 21 2016"
        }
      ]

    }

  },
  methods: {
    getLimitedTitle(title) {
      // Split the title by spaces and limit to 5 words
      const words = title.split(' ');
      return words.slice(0, 5).join(' ') + (words.length > 5 ? '...' : '');
    }
  }


}
</script>
<style scoped>
/********** Slider CSS Starts **********/

.subject-box {

  height: 15rem;
  background: rgb(27, 179, 184);
  border-bottom: 1px solid goldenrod;
  border-radius: 5px;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: width 0.5s ease-in-out;
  justify-content: center;
  align-content: center;


}
.subject-box:hover {
  background: rgb(27, 179, 184);
  border-bottom: 2px solid goldenrod;
}

.subject-box h2 {
  font-size: 18px;
  color: white; /* White text for headings */
}

.subject-box p {
  font-size: 13px;
  color: white;
}

.subject-box .subject-description {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 179, 184, 0.86);
  color: white;
  font-size: 13px;
  padding: 20px;
  line-height: 24px;
  box-sizing: border-box;
  transition: top 0.5s ease-in-out;
}

.subject-box:hover .subject-description {
  top: 0;
}

.subject-box i {
  margin-top: 3px;
  font-size: 34px;

}

/********** Slider CSS Starts **********/




@media screen and (max-width: 425px) {

  .subject-box {

    height: 10rem;
    border-radius: 5px;



  }


  .subject-box h2 {
    font-size: 16px;
  }

  .subject-box p {
    font-size: 12px;
    color: white;
  }

  .subject-box .subject-description {
    font-size: 13px;
    padding: 5px;
    line-height: 18px;

  }
  .subject-box i {
    margin-top: 3px;
    font-size: 34px;

  }


}





</style>