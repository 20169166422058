<template>
  <div class="row">
  <div class="col-md-12">
    <div class="banner ">
      <h3 class="box-heading">Contact Us</h3>
    </div>
  </div>
</div>




        <div class="col-lg-12">
          <div class="contact-area mt-4">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                <div class="contact-form">
                  <div class="single-title">
                    <h3>Send A Message</h3>
                  </div>
                  <div class="contact-form-container">
                    <form
                        id="contact-form"
                        action="http://whizthemes.com/mail-php/contact/mail.php"
                        method="post"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <input
                              type="text"
                              name="name"
                              placeholder="Your Name *"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                              type="email"
                              name="email"
                              placeholder="Your Email *"
                          />
                        </div>
                      </div>
                      <input type="text" name="subject" placeholder="Subject *" />
                      <textarea
                          name="message"
                          class="yourmessage form-control"
                          placeholder="Your message"

                          rows="2"
                      ></textarea>
                      <button
                          type="submit"
                          class="button-default button-yellow submit"
                      >
                        <i class="fa fa-send"></i>Submit
                      </button>
                    </form>
                    <p class="form-messege"></p>
                  </div>
                </div>
              </div>
                <div class="col-lg-6">
                  <div class="contact-area-container">
                    <div class="single-title">
                      <h3>Contact Info</h3>
                    </div>
                    <p>
                      For more information about us you can send us an email at
                      <router-link to="#">info@southmews</router-link>
                      and we will be happy to respond to you.

                    </p>
                    <div class="contact-address-container">
                      <div class="contact-address-info">
                        <div class="contact-icon">
                          <i class="fa fa-map-marker"></i>
                        </div>
                        <div class="contact-text">
                          <h4>Address</h4>
                          <span>19715 New Cerney Park, <br>
Saturday Retreat, Harare south, <br>
Zimbabwe</span>
                        </div>
                      </div>
                      <div class="contact-address-info">
                        <div class="contact-icon">
                          <i class="fa fa-phone"></i>
                        </div>
                        <div class="contact-text">
                          <h4>Phone</h4>
                          <span>+263 77 692 0588 | +263 77 640 3559</span>
                        </div>
                      </div>
                      <div class="contact-address-info">
                        <div class="contact-icon">
                          <i class="fa fa-globe"></i>
                        </div>
                        <div class="contact-text">
                          <h4>Our Social Platforms</h4>
                          <span class="social-links ">
                            <a href="#"><i class="fa fa-facebook"></i></a>
                            <a href="#"><i class="fa fa-google-plus"></i></a>
                            <a href="#"><i class="fa fa-twitter"></i></a>
                            <a href="#"><i class="fa fa-instagram"></i></a>
                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>


        <div class="col-md-12">
          <div class="map mt-2">
            <h3 class="box-heading w-25 " >Location</h3>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d948.9460842350406!2d30.9464132683842!3d-17.942210077937432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931a18d5664aea7%3A0xd8799440d3b61261!2sSouthmews%20High%20School!5e0!3m2!1sen!2szw!4v1709543962468!5m2!1sen!2szw"
                class="width-100"
                height="450"
                style="border: 2px solid gray"
                allow="fullscreen"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>


</template>

  <script>

  import SuccessPopup from "@/components/successPopup.vue";
  import {services} from "@/services/axios.js";
export default {
  name: "ContactUs",
  components: {SuccessPopup},



  methods: {
    submitForm: async function () {
      this.clearErrors();
      let isValid = true;

      if (this.contactDetails.firstname=== '') {
        this.showError('name', 'Please enter your name.');
        isValid = false;
      }


      if (this.contactDetails.lastname=== '') {
        this.showError('lastname', 'Please enter your last Name.');
        isValid = false;
      }

      if (!this.isValidEmail(this.contactDetails.email)) {
        this.showError('email', 'Please enter a valid email address.');
        isValid = false;
      }

      if (this.contactDetails.phonenumber=== '') {
        this.showError('phonenumber', 'Please enter phone number.');
        isValid = false;
      }
      if (this.contactDetails.message=== '') {
        this.showError('message', 'Please type you message .');
        isValid = false;
      }


      if (isValid) {
        try {
          let response= await services.saveMessage(this.contactDetails)
             if (response){
               this.isSent=true
               this.showpopup=true
               this.response="message sent";
               this.nameclass='success';
               this.$router.push('/')
             }
             else{
               this.isSent=false
               this.showpopup=true
               this.response="message sending failed"
               this.nameclass='error';
               this.$router.push('/ContactUs')
          }

        }
        catch (error){

          console.log(error)

        }







      }

    },
    showError(field, message) {
      this[`${field}Error`] = message;
    },
    clearErrors() {
      this.nameError = '';
      this.lastnameError = '';
      this.phonenumberError = '';
      this.messageError = '';

      this.emailError = '';

    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    formatAddress(address) {
      return address.replace(/\n/g, '<br>');
    },
    reset() {
      this.contactDetails.name= '';
      this.contactDetails.lastname= '';
      this.contactDetails.phonenumber= '';
      this.contactDetails.email='';
      this.contactDetails.message= '';

    },
  },

};
</script>

  <style scoped>
  .banner {
    background: url(../assets/images/contactus2.webp) center no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 36px;
    text-align: left;

  }

.map {
  flex-basis: 60%;
}

iframe {
  width: 100%;
  height: 450px;
  border: 2px solid gray;
}

  @media screen and (max-width: 320px) {

    .banner {
      background-size: 110px;
      height: 150px;
    }
    .box-heading{

      padding: 1px;

    }
    h3 {
      margin-bottom: 1px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 375px) {

    .banner {
      background-size: 330px;
      height: 150px;
    }
    .box-heading{

      padding: 1px;

    }
    h3 {
      margin-bottom: 1px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 425px) {

    .banner {
      background-size: 425px;
      height: 150px;
      margin-bottom: 0;
    }
    .box-heading{
      padding: 3px;

    }
    h3 {
      margin-bottom: 3px;
      font-size: 20px;
    }
  }

</style>