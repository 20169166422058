
<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center align-content-center test">
      <div v-show="!isFormVisible && !isSuccess" class="col-12 col-lg-  ">
        <div class="welcome-message">
          <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 ">
              <div class="row d-flex ">
                <div class="col-lg-12">
                  <div class="single-title">
                    <h3>Welcome to Our Online Registration Platform</h3>
                  </div>

                  <p>Southmews has implemented an online enrollment platform to simplify the student registration process. The platform allows prospective and current students at Southmews to conveniently manage their academic records and enrollment details through a secure web-based interface. Our admin team will respond to you as soon as possible.</p>

                </div>

              </div>
            </div>
            <div class="col-lg-6">
              <div class="section application-process">
                <div class="single-title">
                  <h3>Application Process</h3>
                </div>

                <p>Upon submitting your application, your child’s name will be entered on our enrolment list. Acceptance of this registration does not imply final acceptance of the candidate. Final acceptance will depend on:</p>
                <ul>
                  <li>The availability of a place</li>
                  <li>An entrance examination</li>
                  <li>All other selection criteria being met</li>
                </ul>
                <p>Once your application is complete, our team will review it and contact you regarding the next steps.</p>
                <p>Please note that there is a registration fee of USD $50. This fee is necessary for processing your application and securing your place at our school.</p>

              </div>
            </div>
          </div>
            <button class="button-default" @click="showForm">Proceed to Registration</button>
          </div>
        </div>
      </div>
      <div v-show="isFormVisible && !isSuccess"  class="col-lg-12 col-md-5 col-12 p-3">
        <div class="row justify-content-center align-content-center">
          <div class="col-lg-9">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mb-0">Register a New Student</h4>
              </div>
              <div class="card-body">
                <div id="progress-wizard" class="twitter-bs-wizard">
                  <ul class="twitter-bs-wizard-nav nav nav-pills nav-justified ">
                    <li class="nav-item">
                      <a href="#student-info" class="nav-link " data-toggle="tab">
                        <div class="step-icon" data-bs-toggle="tooltip" title="Student Information">
                          <i class="fa fa-user"></i>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#parent-info" class="nav-link" data-toggle="tab">
                        <div class="step-icon" data-bs-toggle="tooltip" title="Parent/Guardian Information">
                          <i class="fa fa-users"></i>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#emergency-contact" class="nav-link" data-toggle="tab">
                        <div class="step-icon" data-bs-toggle="tooltip" title="Emergency Contact">
                          <i class="fa fa-exclamation-circle"></i>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#academic-info" class="nav-link" data-toggle="tab">
                        <div class="step-icon" data-bs-toggle="tooltip" title="Academic Information">
                          <i class="fa fa-book"></i>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#health-info" class="nav-link" data-toggle="tab">
                        <div class="step-icon" data-bs-toggle="tooltip" title="Health Information">
                          <i class="fa fa-heartbeat"></i>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#additional-info" class="nav-link" data-toggle="tab">
                        <div class="step-icon" data-bs-toggle="tooltip" title="Additional Information">
                          <i class="fa fa-info-circle"></i>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#fees-payments" class="nav-link" data-toggle="tab">
                        <div class="step-icon" data-bs-toggle="tooltip" title="Fees and Payments">
                          <i class="fa fa-dollar"></i>
                        </div>
                      </a>
                    </li>
                  </ul>

                  <div class="tab-content twitter-bs-wizard-tab-content">

                    <div class="tab-pane active" id="student-info">
                      <h3>Student Information</h3>
                      <form>
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-6">
                                <div class="mb-1">
                                  <label class="form-label"
                                         for="student-firstName">First Name</label>
                                  <input type="text"
                                         class="form-control"
                                         id="student-firstName"
                                         required placeholder="First name"
                                         v-model="student.firstName">
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="mb-1">
                                  <label class="form-label" for="student-lastName">Last name</label>
                                  <input v-model="student.lastName" type="text" class="form-control" id="student-LastName" required placeholder="Last name">
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="mb-1">
                                  <label class="form-label" for="student-dob">Date of Birth</label>
                                  <input v-model="student.dob" type="date" class="form-control" id="student-dob" required>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="mb-1">
                                  <label class="form-label" for="student-gender">Gender</label>
                                  <select v-model="student.gender" class="form-control form-select" id="student-gender" required>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="non-binary">Non-binary</option>
                                    <option value="prefer-not-to-say">Prefer not to say</option>
                                  </select>
                                </div>

                              </div>

                              <div class="col-lg-6">
                                <div class="mb-1 ">
                                  <label class="form-label" for="student-contact">Phone Number</label>

                                  <input v-model="student.contact" type="text" class="form-control" id="student-contact" placeholder="Phone number">


                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="mb-1 ">
                                  <label class="form-label" for="student-contact">Email</label>

                                  <input v-model="student.email" type="email" class="form-control" id="student-email" placeholder="Email address (if applicable)">


                                </div>
                              </div>

                              <div class="col-lg-12">

                                <div class="mb-1">
                                  <label class="form-label" for="student-address">Address</label>
                                  <textarea v-model="student.address" class="form-control" name="" id="student-address" required cols="3" rows="3" placeholder="Current residential address"></textarea>
                                </div>
                              </div>


                            </div>


                          </div>

                        </div>


                        <ul class="pager wizard twitter-bs-wizard-pager-link">
                          <li class="next">
                            <a href="javascript:void(0);" class="button-default" onclick="wizard.next()">
                              Next
                              <i class="fa fa-chevron-right ms-1"></i>
                            </a>
                          </li>
                        </ul>
                      </form>
                    </div>

                    <div class="tab-pane" id="parent-info">
                      <h3>Parent/Guardian Information</h3>
                      <form>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="guardian-firstName">First name</label>
                              <input v-model="guardian.firstName"
                                  type="text" class="form-control"
                                     id="guardian-firstName"
                                     required
                                     placeholder="First name of  parent/guardian">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label"  for="guardian-LastName">LastName</label>
                              <input v-model="guardian.lastName" type="text" class="form-control"
                                     id="guardian-LastName"
                                     required
                                     placeholder="LastName of  parent/guardian">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label"  for="guardian-relationship">Relationship to Student</label>
                              <input  v-model="guardian.relationship" type="text" class="form-control" id="guardian-relationship" required placeholder="e.g., mother, father, guardian">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label"  for="guardian-contact">Email</label>

                              <input v-model="guardian.email" type="email" class="form-control mt-2" id="guardian-email" placeholder="Email address">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label"  for="guardian-contact">Phone Number</label>
                              <input  v-model="guardian.contact" type="tel" class="form-control" id="guardian-contact" required placeholder="Phone number">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label"  for="guardian-occupation">Occupation</label>
                              <input v-model="guardian.occupation" type="text" class="form-control" id="guardian-occupation" placeholder="Job title and place of employment">
                            </div>
                          </div>
                        </div>
                        <ul class="pager wizard twitter-bs-wizard-pager-link">
                          <li class="previous ">
                            <a href="javascript:void(0);" class="button-default" onclick="wizard.previous()">
                              <i class="fa fa-chevron-left me-1"></i>
                              Previous</a>
                          </li>
                          <li class="next">
                            <a href="javascript:void(0);" class="button-default" onclick="wizard.next()">Next
                              <i class="fa fa-chevron-right ms-1"></i>
                            </a>
                          </li>
                        </ul>
                      </form>
                    </div>


                    <div class="tab-pane" id="emergency-contact">
                      <h3>Emergency Contact</h3>
                      <form>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="emergency-firstName">Full Name</label>
                              <input  v-model="emergencyContact.name" type="text" class="form-control" id="emergency-firstName" required placeholder="Name of additional contact person">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="emergency-relationship">Relationship to Student</label>
                              <input v-model="emergencyContact.relationship" type="text" class="form-control" id="emergency-relationship" required placeholder="e.g., aunt, neighbor">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="emergency-contact">Phone Number</label>
                              <input v-model="emergencyContact.phone" type="email" class="form-control mt-2" id="emergency-email" placeholder="Email address">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="emergency-contact">Email</label>
                              <input v-model="emergencyContact.email" type="email" class="form-control mt-2" id="emergency-email" placeholder="Email address">
                            </div>
                          </div>
                        </div>




                        <ul class="pager wizard twitter-bs-wizard-pager-link">
                          <li class="previous">
                            <a href="javascript:void(0);" class="button-default" onclick="wizard.previous()">
                              <i class="fa fa-chevron-left me-1"></i>
                              Previous</a>
                          </li>
                          <li class="next"><a href="javascript:void(0);" class="button-default" onclick="wizard.next()">
                            Next <i class="fa fa-chevron-right ms-1"></i></a></li>
                        </ul>
                      </form>
                    </div>

                    <!-- Academic Information Tab -->
                    <div class="tab-pane" id="academic-info">
                      <h3>Academic Information</h3>
                      <form>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="previous-school">Previous School</label>
                              <input v-model="academic.previousSchool" type="text" class="form-control" id="previous-school" required placeholder="Name and address of last school attended">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="grade-level">Grade Level Applying For</label>
                              <select v-model="academic.gradeLevel" class="form-control form-select" id="student-gender" required>
                                <option value="">Select Grade</option>
                                <option value="male">ECD A</option>
                                <option value="male">ECD B</option>
                                <option value="female">Grade 1</option>
                                <option value="non-binary">Grade 2</option>
                                <option value="prefer-not-to-say">Grade 3</option>
                                <option value="male">Grade 4</option>
                                <option value="female">Grade 5</option>
                                <option value="non-binary">Grade 6</option>
                                <option value="prefer-not-to-say">Grade 7</option>


                              </select>

                            </div>

                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1 ">
                              <label class="form-label" for="academic-records">Academic Records</label>
                              <input @change="handleFileUpload" class="form-control" type="file" name="academic-record" id="academic-records">
                              <span class="form-text text-warning">

                                      Upload the latest transcript.</span>

                            </div>

                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="special-achievements">Special Achievements</label>
                              <textarea  v-model="academic.specialAchievements" class="form-control" id="special-achievements" placeholder="Notable achievements"></textarea>
                            </div>
                          </div>
                        </div>


                        <ul class="pager wizard twitter-bs-wizard-pager-link">
                          <li class="previous">
                            <a href="javascript:void(0);" class="button-default" onclick="wizard.previous()">
                              <i class="fa fa-chevron-left me-1"></i>
                              Previous
                            </a>
                          </li>
                          <li class="next">
                            <a href="javascript:void(0);" class="button-default" onclick="wizard.next()">
                              Next <i class="fa fa-chevron-right ms-1">

                            </i>
                            </a>
                          </li>
                        </ul>
                      </form>
                    </div>

                    <!-- Health Information Tab -->
                    <div class="tab-pane" id="health-info">
                      <h3>Health Information</h3>
                      <form>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="medical-conditions">Medical Conditions</label>
                              <textarea v-model="health.medicalConditions" class="form-control" id="medical-conditions" placeholder="Chronic illnesses or conditions"></textarea>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="allergies">Allergies</label>
                              <textarea v-model="health.allergies"  class="form-control" id="allergies" placeholder="List any known allergies"></textarea>
                            </div>

                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="medications">Medications</label>
                              <textarea v-model="health.medications" class="form-control" id="medications" placeholder="Any medications currently taking"></textarea>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-1">
                              <label class="form-label" for="doctor-info">Doctor’s Information</label>
                              <input v-model="health.doctorInfo" type="text" class="form-control" id="doctor-info" placeholder="Name and contact details of primary physician">
                            </div>

                          </div>
                        </div>


                        <ul class="pager wizard twitter-bs-wizard-pager-link">
                          <li class="previous"><a href="javascript:void(0);" class="button-default" onclick="wizard.previous()"><i class="fa fa-chevron-left me-1"></i> Previous</a></li>
                          <li class="next"><a href="javascript:void(0);" class="button-default" onclick="wizard.next()">Next <i class="fa fa-chevron-right ms-1"></i></a></li>
                        </ul>
                      </form>
                    </div>

                    <!-- Additional Information Tab -->
                    <div class="tab-pane" id="additional-info">
                      <div class="row d-flex">
                        <div class="col-lg-6">
                          <h3>Additional Information</h3>
                          <form>
                            <div class="row">
                              <div class="col-lg-6">
                                <label>Languages Spoken</label>
                                <div class="mb-3 d-flex">

                                  <div class="form-check  ">
                                    <input v-model="additional.languagesSpoken" type="radio" class="form-check-input " name="languages-spoken" id="language-english" value="english">
                                    <label class="form-check-label" for="language-english">English</label>
                                  </div>
                                  <div class="form-check mx-1">
                                    <input type="radio" class="form-check-input" name="languages-spoken" id="language-spanish" value="shona" checked>
                                    <label class="form-check-label" for="language-spanish" >Shona</label>
                                  </div>
                                  <div class="form-check">
                                    <input type="radio" class="form-check-input" name="languages-spoken" id="language-other" value="other">
                                    <label class="form-check-label" for="language-other">Other</label>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="mb-3">
                                  <label for="transportation-needs">Transportation Needs</label>
                                  <select v-model="additional.transportationNeeds" class="form-control form-select" id="transportation-needs">
                                    <option value="">Select an option</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="mb-3">
                                  <label for="special-education-needs">Special Education Needs</label>
                                  <input v-model="additional.specialEducationNeeds" type="text" class="form-control" id="special-education-needs" placeholder="Details about special education requirements"/>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="mb-3">
                                  <label for="dietary-restrictions">Dietary Restrictions</label>
                                  <input v-model="additional.dietaryRestrictions" type="text" class="form-control" id="dietary-restrictions" placeholder="Any dietary restrictions or preferences">
                                </div>
                              </div>


                            </div>



                          </form>
                        </div>
                        <div class="col-lg-6">
                          <h3>Consent and Agreement</h3>
                          <form>


                            <div class="row">
                              <div class="col-lg-6">
                                <div class="mb-3">

                                  <div class="mb-3 form-check">
                                    <br>
                                    <input v-model="consent" type="checkbox" class="form-check-input" id="parent-consent" required value="Agree to school policies and procedures">
                                    <label class="form-check-label" for="parent-consent">I agree to school policies and procedures</label>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="mb-3">
                                  <label for="signature-date">Date</label>
                                  <input  v-model="signatureDate" type="date" class="form-control" id="signature-date" required>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12">

                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="col-lg-12">
                          <ul class="pager wizard twitter-bs-wizard-pager-link">
                            <li class="previous">
                              <a href="javascript:void(0);" class="button-default" onclick="wizard.previous()">
                                <i class="fa fa-chevron-left me-1"></i> Previous
                              </a>
                            </li>
                            <li class="next">
                              <a href="javascript:void(0);" class="button-default" onclick="wizard.next()">
                                Next <i class="fa fa-chevron-right ms-1"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>                          <!-- Legal Documents Tab -->


                    <div class="tab-pane" id="fees-payments">
                      <h3>Fees and Payments</h3>
                      <form>
                        <div class="mb-3">
                          <label for="application-fee">Application Fee</label>
                          <input v-model="fees.applicationFee" type="text" class="form-control" id="application-fee" required placeholder="Details about any application fee required">
                        </div>
                        <div class="mb-3">
                          <label for="payment-info">Payment Information</label>
                          <textarea v-model="fees.paymentInfo" class="form-control" id="payment-info" required placeholder="Instructions for payment methods (e.g., credit card, bank transfer)"></textarea>
                        </div>
                        <ul class="pager wizard twitter-bs-wizard-pager-link">
                          <li class="previous"><a href="javascript:void(0);" class="button-default" onclick="wizard.previous()"><i class="fa fa-chevron-left me-1"></i> Previous</a></li>
                          <li class="float-end">
                            <span class="button-default" @click.prevent="submitData()" >Submit Registration <i class="fa fa-check ms-1"></i></span></li>

                        </ul>
                      </form>

                      <div v-if="savedData" class="saved-data">
                        <h3>Saved Registration Data</h3>
                        <pre>{{ savedData }}</pre>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isSuccess" class="col-lg-12 col-md-5 col-12 p-3">
        <div class="container">
          <h1>Registration Successful!</h1>
          <p>Thank you for registering at Southmews! Your application has been received successfully.</p>
          <p>We will review your application and notify you of the next steps as soon as possible. Please ensure that you check your email for any updates regarding your application status.</p>

          <h2>Next Steps</h2>
          <p>If you have any questions or need further assistance, feel free to contact our support team at <a href="mailto:support@southmews.com">support@southmews.com</a>.</p>


        </div>
      </div>

    </div>
  </div>
</template>



<script>

export default {

  data() {
    return {
      student: {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        contact: '',
        email: '',
        address: '',
      },
      guardian: {
        firstName: '',
        lastName: '',
        relationship: '',
        email: '',
        contact: '',
        occupation: '',
      },
      emergencyContact: {
        name: '',
        relationship: '',
        phone: '',
        email: '',
      },
      academic: {
        previousSchool: '',
        gradeLevel: '',
        specialAchievements: '',
        academicRecord: null, // For file upload
      },
      health: {
        medicalConditions: '',
        allergies: '',
        medications: '',
        doctorInfo: '',
      },
      additional: {
        languagesSpoken: '',
        transportationNeeds: '',
        specialEducationNeeds: '',
        dietaryRestrictions: '',
      },
      consent: false,
      signatureDate: '',
      fees: {
        applicationFee: '',
        paymentInfo: '',
      },
      savedData: null,
      isFormVisible: false,
      isSuccess: false,
    };
  },
  mounted() {

    this.loadFromLocalStorage();
 },
  methods: {
    showForm() {
      this.isFormVisible = true;

    },
    submitData() {
      // Save data to localStorage
      localStorage.setItem('registrationData', JSON.stringify(this.getFormData()));
      alert('Registration data saved locally!');
      this.isSuccess = true;
      this.isFormVisible = false;
      this.resetForm()
    },
    loadFromLocalStorage() {
      const savedData = localStorage.getItem('registrationData');
      if (savedData) {
        const data = JSON.parse(savedData);
        this.student = { ...this.student, ...data.student };
        this.guardian = { ...this.guardian, ...data.guardian };
        this.emergencyContact = { ...this.emergencyContact, ...data.emergencyContact };
        this.academic = { ...this.academic, ...data.academic };
        this.health = { ...this.health, ...data.health };
        this.additional = { ...this.additional, ...data.additional };
        this.consent = data.consent;
        this.signatureDate = data.signatureDate;
        this.fees = { ...this.fees, ...data.fees };
      }
    },
    getFormData() {
      return {
        student: this.student,
        guardian: this.guardian,
        emergencyContact: this.emergencyContact,
        academic: this.academic,
        health: this.health,
        additional: this.additional,
        consent: this.consent,
        signatureDate: this.signatureDate,
        fees: this.fees,
      };
    },
    clearLocalStorage() {
      localStorage.removeItem('registrationData');
      this.resetForm();
      alert('Saved data cleared!');
    },
    viewLocalStorage() {
      const savedData = localStorage.getItem('registrationData');
      this.savedData = savedData ? JSON.parse(savedData) : 'No data found.';
    },
    resetForm() {
      this.student = { firstName: '', lastName: '', dob: '', gender: '', contact: '', email: '', address: '' };
      this.guardian = { firstName: '', lastName: '', relationship: '', email: '', contact: '', occupation: '' };
      this.emergencyContact = { name: '', relationship: '', phone: '', email: '' };
      this.academic = { previousSchool: '', gradeLevel: '', specialAchievements: '', academicRecord: null };
      this.health = { medicalConditions: '', allergies: '', medications: '', doctorInfo: '' };
      this.additional = { languagesSpoken: '', transportationNeeds: '', specialEducationNeeds: '', dietaryRestrictions: '' };
      this.consent = false;
      this.signatureDate = '';
      this.fees = { applicationFee: '', paymentInfo: '' };
      this.savedData = null; // Reset saved data display
    },
    handleFileUpload(event) {
      this.academic.academicRecord = event.target.files[0]; // Handle file upload
    },
  },
};
</script>
<style>
</style>

