<template>
  <div class="wrapper">
    <div class="row">
      <div class="container">
        <div class="col-md-12">
          <header class="header-three">
            <div class="header-top">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-7 col-md-8 col-3">
                    <div class="header-top-info ">
                      <!--                  <p  v-if="!mobile">Toolverse, all in one </p>-->
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-4 col-12">
                    <div class="header-login-register d-flex">
                      <ul class="login">
                        <li>
                          <router-link to="#"><i class="fa fa-key"></i>Login</router-link>
                          <div class="login-form">
                            <h4>Login</h4>
                            <form action="#" method="post">
                              <div class="form-box">
                                <i class="fa fa-user"></i>
                                <input type="text" name="user-name" placeholder="Username" />
                              </div>
                              <div class="form-box">
                                <i class="fa fa-lock"></i>
                                <input type="password" name="user-password" placeholder="Password" />
                              </div>
                              <div class="button-box">
                                <button type="submit" class="login-btn">Login</button>
                              </div>
                            </form>
                          </div>
                        </li>
                      </ul>
                      <ul class="register">
                        <li>
                          <router-link to="#"><i class="fa fa-lock"></i>Sign Up</router-link>
                          <div class="register-form">
                            <h4>Sign Up</h4>
                            <span>Please sign up using account detail below.</span>
                            <form action="#" method="post">
                              <div class="form-box">
                                <i class="fa fa-user"></i>
                                <input type="text" name="user-name" placeholder="Username" />
                              </div>
                              <div class="form-box">
                                <i class="fa fa-lock"></i>
                                <input type="password" name="user-password" placeholder="Password" />
                              </div>
                              <div class="form-box">
                                <i class="fa fa-envelope"></i>
                                <input type="email" name="user-email" placeholder="Email" />
                              </div>
                              <div class="button-box">
                                <input checked data-toggle="toggle" type="checkbox" />
                                <span>Remember me</span>
                                <button type="submit" class="register-btn">Register</button>
                              </div>
                            </form>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="header-logo-menu sticker">
              <div class="container-fluid">
                <div class="logo-menu-bg">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="logo">
                            <div class="row">
                              <div class="col-md-6 col-6">
                                <router-link class="mt-1"   to="/"><img
                                    src="../assets/images/logo.png"
                                    alt="Toolverse"
                                    width="45"
                                /></router-link>
                              </div>
                              <div class="col-md-6 col-6 float-end">
                                <div v-if="mobile" class="mobile-menu-area">
                                  <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                      <div class="mobile-menu">
                                        <nav class="navbar navbar-expand-md
                                       navbar-dark  float-end">

                                          <button class="navbar-toggler float-end text-black"
                                                  type="button"
                                                  data-bs-toggle="offcanvas"
                                                  data-bs-target="#offcanvasNavbar"
                                                  aria-controls="offcanvasNavbar">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                              <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                          </button>
                                          <div class="offcanvas offcanvas-end w-50 h-75 rounded "
                                               tabindex="-5"
                                               id="offcanvasNavbar"
                                               aria-labelledby="offcanvasNavbarLabel">
                                            <div class="offcanvas-header bg-light ">
                                              <router-link class=""   to="/">
                                                <img
                                                    src="../assets/images/logo.png"
                                                    alt="Toolverse"
                                                    width="35"
                                                />
                                              </router-link>
                                              <button type="button" class="btn-close btn-sm  "
                                                      data-bs-dismiss="offcanvas"
                                                      aria-label="Close">

                                              </button>
                                            </div>
                                            <div class="offcanvas-body bg-primary-subtle"id="nav">

                                              <ul class="navbar-nav justify-content-evenly w-100"
                                                  id="nav ">
                                                <li class="nav-item">
                                                  <router-link class="nav-link  dropdown text-black" aria-current="page" to="/">Home</router-link>
                                                </li>
                                                <li class="nav-item">
                                                  <router-link class="nav-link  dropdown text-black" aria-current="page" to="/AdmissionForm">Admission</router-link>
                                                </li>

                                                <li class="nav-item dropdown">
                                                  <router-link class="nav-link text-black " to="#!" id="accountDropdown"  data-bs-toggle="dropdown" aria-expanded="false">  Payments</router-link>
                                                  <ul class="dropdown-menu dropdown-content  border-0 shadow bsb-zoomIn" aria-labelledby="accountDropdown">

                                                    <li><router-link class="dropdown-item text-black" to="/payments">Make Payment</router-link></li>
                                                    <li><router-link class="dropdown-item text-black" to="feesStructure">School Fees Structure</router-link></li>
                                                  </ul>
                                                </li>
                                                <li class="nav-item">
                                                  <router-link class="nav-link  text-black" aria-current="page" to="/SubjectsAndCourses">Subjects</router-link>
                                                </li>
                                                <li class="nav-item dropdown ">
                                                  <router-link class="nav-link text-black"
                                                               to="#!"
                                                               id="accountDropdown"
                                                               data-bs-toggle="dropdown"
                                                  > Resources

                                                    <i class="fa fa-angle-down mt-1 ms-1"/>
                                                  </router-link>
                                                  <ul class="dropdown-menu dropdown-content  border-0 shadow bsb-zoomIn" aria-labelledby="accountDropdown">
                                                    <!--                    <li><router-link class="dropdown-item" to="/PrivacyPolicy">Privacy Policy & Licenses</router-link></li>-->
                                                    <li><router-link class="dropdown-item text-black" to="/howToApply">How To Apply</router-link></li>
                                                    <!--                    <li><router-link class="dropdown-item" to="#">Student Info & Forms</router-link></li>-->
                                                    <!--                    <li><router-link class="dropdown-item" to="#">Records Request</router-link></li>-->
                                                    <li><router-link class="dropdown-item text-black" to="/Parents">Parents</router-link></li>
                                                  </ul>
                                                </li>
                                                <li class="nav-item dropdown">
                                                  <router-link class="nav-link text-black" to="/about" >About US</router-link>
                                                  <!--                  <ul class="dropdown-menu dropdown-content  border-0 shadow bsb-zoomIn" aria-labelledby="servicesDropdown">-->
                                                  <!--                    <li><router-link class="dropdown-item" to="/about">At a Glance</router-link></li>-->
                                                  <!--                    <li><router-link class="dropdown-item" to="/History">History</router-link></li>-->
                                                  <!--                    <li><router-link class="dropdown-item" to="/Teachers">Stuff Team</router-link></li>-->
                                                  <!--                  </ul>-->
                                                </li>
                                                <!--                <li class="nav-item dropdown">-->
                                                <!--                  <router-link class="nav-link" to="/AllEvents">Events</router-link>-->
                                                <!--                </li>-->
                                                <li class="nav-item dropdown">
                                                  <router-link class="nav-link text-black" to="/ContactUs">Contact us</router-link>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>




                                        </nav>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col-lg-12 d-none d-lg-block">
                              <div class="mainmenu-area">
                                <div class="mainmenu">
                                  <nav>
                                    <ul id="nav" class="mt-3 mb-0">
                                      <li ><router-link
                                          to="/">
                                        Home
                                      </router-link></li>
                                      <li>
                                        <router-link
                                            to="/AdmissionForm">
                                          Admission
                                        </router-link>
                                      </li>

                                      <li class="current">

                                        <router-link to="">Payments <i class="fa fa-angle-down"></i></router-link>
                                        <ul class="sub-menu">
                                          <li><router-link  to="/payments">Make Payment</router-link></li>
                                          <li><router-link  to="feesStructure">School Fees Structure</router-link></li>

                                        </ul>
                                      </li>
                                      <li>
                                        <router-link to="">Resources <i class="fa fa-angle-down"></i></router-link>
                                        <ul class="sub-menu">
                                          <li><router-link to="/howToApply">How To Apply</router-link></li>
                                          <li><router-link to="/Parents">Parents</router-link></li>

                                        </ul>
                                      </li>

                                      <li>
                                        <router-link  to="/SubjectsAndCourses">Subjects</router-link>
                                      </li>
                                      <li><router-link  to="/about" >About US</router-link></li>

                                      <li><router-link  to="/ContactUs">Contact us</router-link></li>
                                    </ul>
                                  </nav>
                                </div>
                                <!--                              <ul class="header-search">-->
                                <!--                                <li class="search-menu">-->
                                <!--                                  <i id="toggle-search" class="fa fa-search"></i>-->
                                <!--                                </li>-->
                                <!--                              </ul>-->
                                <!--                              <div class="search">-->
                                <!--                                <div class="search-form">-->
                                <!--                                  <form id="search-form" action="#">-->
                                <!--                                    <input type="search" placeholder="Search here..." name="search" />-->
                                <!--                                    <button type="submit"><span><i class="fa fa-search"></i></span></button>-->
                                <!--                                  </form>-->
                                <!--                                </div>-->
                                <!--                              </div>-->
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>



          </header>
        </div>

      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: "navbar",
  data() {
    return {

      mobile: null,
      windownWidth: null,
    };
  },

  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {

    checkScreen() {
      this.windownWidth = window.innerWidth;
      if (this.windownWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;

    },

  }

};
</script>
