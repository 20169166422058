

<template>


      <div class="row">
        <div class="col-md-12">
          <div class="banner mt-2">
            <h3 class="box-heading">Event Details</h3>
          </div>
        </div>
      </div>


  <div class="blog-details-area section-padding blog-two">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-7">
          <div class="blog-post-wrapper">
            <div class="blog-post-details">
              <h1>{{event.title}}</h1>
              <span
              ><i class="fa fa-calendar"></i>{{ event.date }}/ By: Admin</span
              >
            </div>
            <div class="blog-post-details-img">
              <img :src="event.image" alt="event" width="500" />
            </div>
            <div class="blog-post-details-text">
              <p>
                {{event.description}}
              </p>



            </div>
            <div class="single-title">
              <h3>Related Posts</h3>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-6 col-12" v-for="event in related" :key="event.eventId">
                <div class="single-blog-item overlay-hover">
                  <div class="single-blog-image">
                    <div class="overlay-effect">
                      <a :href="`${event.eventId}`">
                        <img :src="event.image" alt="event" />
                        <span class="class-date"
                        >{{ event.date.split(',')[0].split( ' ')[0].slice(0,3) }} {{ event.date.split(',')[0].split( ' ')[1] }}<span>{{ event.date.split(',')[1] }}</span></span
                        >
                      </a>
                    </div>
                  </div>
                  <div class="single-blog-text">
                    <h4>
                      <a :href="`${event.eventId}`"
                      >{{ event.title }}</a
                      >
                    </h4>
                    <div class="blog-date">
                      <span><i class="fa fa-calendar"></i>{{ event.date }}</span>
                    </div>
                    <p>
                      {{event.description
                      }}
                    </p>
                    <a :href="`${event.eventId}`">Read more.</a>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6 col-12" >
                <div class="error-box text-center font-italic">
                  <p class="mt-1 font-italic">No related events </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5">

          <div class="single-title">
            <h3>Recent Posts</h3>
          </div>
          <div class="row">
            <div  class="col-xl-12 col-lg-12 col-md-12 col-12" v-for="event in recent" :key="event.eventId">
              <div class="single-blog-item overlay-hover">
                <div class="row">

                  <div class="col-md-12">
                    <div class="single-blog-text">
                      <h4>
                        <a :href="`${event.eventId}`"
                        >{{ event.title }}</a
                        >
                      </h4>
                      <div class="blog-date">
                        <span><i class="fa fa-calendar"></i>{{ event.date }}</span>
                      </div>
                      <p>
                        {{event.description
                        }}
                      </p>
                      <a :href="`${event.eventId}`">Read more.</a>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
<!--          <div class="single-widget-item hidden-sm">-->
<!--            <div class="single-widget-container">-->
<!--              <a href="#">-->
<!--                <img src="../assets/images/IMG-20240313-WA0014.jpg" alt=""  height="150" width="250"/>-->
<!--                <span>Enroll Your Child</span>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
          <div class="single-widget-item">
            <div class="single-title">
              <h3>Tags</h3>
            </div>
            <div class="single-widget-container">
              <ul class="tag-list ">
                <li><a href="#">{{ event.subCategory }}</a></li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>


import spinner from "@/components/spinner.vue";


export default {
  components: {spinner},
  data() {
    return {
      loading:"",
      isRelatedAvailable :"",
      currentEventId: parseInt(this.$route.params.eventId),

      error:"",
      event: {},
      events: [
        {
          "eventId": 1,
          "description": "A fascinating annual tech conference showcasing the latest innovations.",
          "category": "Technology",
          "dateCreated": "June 15, 2023",
          "date": "September 10, 2023",
          "image": require("../assets/images/IMG-20241009-WA0054.jpg"),
          "status": "Upcoming",
          "subCategory": "Software",
          "title": "Tech Expo 2023"
        },
        {
          "eventId": 2,
          "description": "A vibrant music festival featuring both local and international artists.",
          "category": "Entertainment",
          "dateCreated": "April 20, 2023",
          "date": "July 25, 2023",
         " image": require("../assets/images/IMG-20241009-WA0069.jpg"),
          "status": "Ongoing",
          "subCategory": "Music",
          "title": "Summer Beats Festival"
        },
        {
          "eventId": 3,
          "description": "An educational symposium discussing the latest advancements in renewable energy solutions.",
          "category": "Environment",
          "dateCreated": "February 1, 2023",
          "date": "November 12, 2023",
          "image": require("../assets/images/IMG-20241009-WA0058.jpg"),
          "status": "Upcoming",
          "subCategory": "Energy",
          "title": "Renewable Energy Symposium"
        },
        {
          "eventId": 4,
          "description": "A culinary extravaganza showcasing the diverse flavors of the region.",
          "category": "Food",
          "dateCreated": "July 1, 2023",
          "date": "August 18, 2023",
          "image": require("../assets/images/IMG-20241009-WA0004.jpg"),
          "status": "Upcoming",
          "subCategory": "Cuisine",
          "title": "Flavor Fest 2023"
        },
      ],

    }
  },
  async created (){

    this.event = this.events.find((event) => event.eventId === this.currentEventId);


  },
  computed:{
   related (){


    let related = this.events.filter(event => event.category === this.event.category
         && event.eventId !== this.currentEventId
     )
     if(related.length >= 1){
       return related
     }
     else {
       this.isRelatedAvailable = false

     }

   },
   recent (){
     return this.events.sort((a,b) => new Date(b.date) - new Date(a.date))
         .filter((event,index)=> index <= 2 && event.eventId !== this.currentEventId)
   }
  }

};
</script>

<style scoped>



</style>