<template>


  <div class="breadcrumb-banner-area">

    <div class="row">
      <div class="col-md-12">
        <div class="banner">
          <h1 class=" box-heading">About Us</h1>
        </div>
      </div>
    </div>



  </div>

  <div class="activity-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6 activity">
          <div class="single-activity">
            <div class="single-activity-icon">
              <i class="fa fa-gamepad"></i>
            </div>
            <h4>Funny Games</h4>
            <p>
              Fusce pretium, est at aliquam semper, leo ante facilisis risus
              in feugiat ipsum augue pellent esque metu.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 activity">
          <div class="single-activity">
            <div class="single-activity-icon">
              <i class="fa fa-fort-awesome"></i>
            </div>
            <h4>Toys</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 activity">
          <div class="single-activity mrg-res-top-md">
            <div class="single-activity-icon">
              <i class="fa fa-futbol-o"></i>
            </div>
            <h4>Activities</h4>
            <p>
              It was popularised in the 1960s with the release of Letraset
              sheets release containing Lorem Ipsum passages
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 activity">
          <div class="single-activity mrg-res-top-md">
            <div class="single-activity-icon">
              <i class="fa fa-pencil"></i>
            </div>
            <h4>Education</h4>
            <p>
              It is a long established fact that a reader will be distracted
              by the readable content of a page looking at layout
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End of Activity Area-->
  <!--About Area Start-->
  <div class="about-area section-padding-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3>Who we are</h3>
              <p> daycare, preschool,primary and high school</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="about-text-container">

               <p>Welcome to <span class="fw-bold fs-6">SouthMews</span>
              We believe that raising a child is a team effort. With a strong team
              supporting them, every child can learn, be well, and thrive. <br>
                 Parents and caregivers are the most powerful, capable, and important
                 members of a child's team. However, all too often, parents have to
                 support their child's well-being alone. <br>
                 Our comprehensive model brings together all the adults in a child's
                 life, including parents, educators, and medical and mental health
                 providers, starting from a very early age.
            </p>


            <section class="our-vision">

              <div class="row">
                <div class="col-md-12">
                  <div class="section-title-wrapper">
                    <div class="section-title">
                      <h3>Our Vision</h3>
                      <p> daycare, preschool,primary and high school</p>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Our vision is to build a model that can be effectively implemented in
                diverse communities and sustained on public funding.
              </p>
            </section>


          </div>
        </div>
        <div class="col-lg-6">
          <div class="skill-image">
            <img src="../assets/images/aboutus1.png" alt="" />
          </div>
        </div>
      </div>


    </div>
  </div>
  <!--End of About Area-->
  <!--Skill And Experience Area Start-->
  <div class="skill-and-experience-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3>Skills and Experience</h3>
              <p> daycare, preschool,primary and high school</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="experience-skill-wrapper">
            <div class="skill-bars orange">
              <div class="skill-bar-item">
                <span>Playing Science</span>
                <div class="progress">
                  <div
                      class="progress-bar wow fadeInLeft"
                      data-progress="80%"
                      style="width: 80%;"
                      data-wow-duration="1.5s"
                      data-wow-delay="1.2s"
                  >
                    <span class="text-top">80%</span>
                  </div>
                </div>
              </div>
              <div class="skill-bar-item">
                <span>Arts And Craft</span>
                <div class="progress">
                  <div
                      class="progress-bar wow fadeInLeft"
                      data-progress="75%"
                      style="width: 75%;"
                      data-wow-duration="1.5s"
                      data-wow-delay="1.2s"
                  >
                    <span class="text-top">75%</span>
                  </div>
                </div>
              </div>
              <div class="skill-bar-item">
                <span>Creative Writing</span>
                <div class="progress">
                  <div
                      class="progress-bar wow fadeInLeft"
                      data-progress="90%"
                      style="width: 90%;"
                      data-wow-duration="1.5s"
                      data-wow-delay="1.2s"
                  >
                    <span class="text-top">90%</span>
                  </div>
                </div>
              </div>
              <div class="skill-bar-item">
                <span>English Lessons</span>
                <div class="progress">
                  <div
                      class="progress-bar wow fadeInLeft"
                      data-progress="70%"
                      style="width: 70%;"
                      data-wow-duration="1.5s"
                      data-wow-delay="1.2s"
                  >
                    <span class="text-top">70%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="experience-skill-wrapper">
            <div class="skill-bars orange">
              <div class="skill-bar-item">
                <span>Arts And Craft</span>
                <div class="progress">
                  <div
                      class="progress-bar wow fadeInLeft"
                      data-progress="75%"
                      style="width: 75%;"
                      data-wow-duration="1.5s"
                      data-wow-delay="1.2s"
                  >
                    <span class="text-top">75%</span>
                  </div>
                </div>
              </div>
              <div class="skill-bar-item">
                <span>Creative Writing</span>
                <div class="progress">
                  <div
                      class="progress-bar wow fadeInLeft"
                      data-progress="90%"
                      style="width: 90%;"
                      data-wow-duration="1.5s"
                      data-wow-delay="1.2s"
                  >
                    <span class="text-top">90%</span>
                  </div>
                </div>
              </div>
              <div class="skill-bar-item">
                <span>Playing Science</span>
                <div class="progress">
                  <div
                      class="progress-bar wow fadeInLeft"
                      data-progress="80%"
                      style="width: 80%;"
                      data-wow-duration="1.5s"
                      data-wow-delay="1.2s"
                  >
                    <span class="text-top">80%</span>
                  </div>
                </div>
              </div>
              <div class="skill-bar-item">
                <span>English Lessons</span>
                <div class="progress">
                  <div
                      class="progress-bar wow fadeInLeft"
                      data-progress="100%"
                      style="width: 100%;"
                      data-wow-duration="1.5s"
                      data-wow-delay="1.2s"
                  >
                    <span class="text-top">100%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End of Skill And Experience Area-->
  <!--Teachers Column Carousel Start-->
  <div class="teachers-column-carousel-area section-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3>Our Teachers</h3>
              <p>We are glad to introduce our professional staff</p>
            </div>
          </div>
        </div>
      </div>
      <div class="teacher-fullwidth-area section-padding">
        <div class="container">
          <div class="row d-flex">



            <div class="col-lg-3 col-md-3 col-sm-3 col-12"  v-for="teacher in teachers" :key="teacher">

              <div class="single-teachers-column text-center">
                <div class="teachers-image-column">
                  <router-link :to="`teacher/${teacher.Id}`">
                    <img :src="teacher.personalDetails.profilePicture" alt="" />
                    <span class="image-hover">
                                        <span><i class="fa fa-edit"></i>View Profile</span>
                                    </span>
                  </router-link>
                </div>
                <div class="teacher-column-carousel-text">
                  <h4>{{ teacher.personalDetails.name }}</h4>
                  <span>{{ teacher.personalDetails.title }}, {{ teacher.personalDetails.college}}</span>
                  <p>{{
                      teacher.description
                    }}</p>
                  <div class="social-links">
                    <router-link to="#"><i class="fa fa-facebook"></i></router-link>
                    <router-link to="#"><i class="fa fa-google-plus"></i></router-link>
                    <router-link to="#"><i class="fa fa-twitter"></i></router-link>
                    <router-link to="#"><i class="fa fa-instagram"></i></router-link>
                  </div>
                </div>
              </div>
            </div>



          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
  data() {
    return {
      teachers:[

        {
          "Id" : 1,
          "degree": "Bachelor's Degree in Elementary Education",
          "experience": 5,
          "hobbies": ["reading", "hiking", "painting"],
          "myCourses": [
            {
              "title": "Grade 1 English",
              "description": "A course on English language and literature for grade 1 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a passionate and dedicated teacher with 5 years of experience.",
          "contactDetails": {
            "phone": "+1234567890",
            "email": "john.doe@example.com"
          },
          "skills": ["Microsoft Office", "Google Classroom",
            "communication", "teamwork", "problem-solving"],

          "schedule": {
            "monday": "9:00 AM - 3:00 PM",
            "tuesday": "10:00 AM - 11:00 AM",
            "wednesday": "9:00 AM - 4:00 PM",
            "thursday": "11:00 AM - 12:00 PM",
            "friday": "10:00 AM - 11:00 AM"
          },
          personalDetails: {
            name: "John Doe",
            title: "Grade 1 Teacher",
            college: "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.48.51.jpeg'),          }
        },



        {
          "Id" : 2,
          "degree": "Bachelor's Degree in Mathematics",
          "experience": 3,
          "hobbies": ["playing chess", "reading", "coding"],
          "myCourses": [
            {
              "title": "Grade 2 Mathematics",
              "description": "A course on mathematics for grade 2 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a math enthusiast with 3 years of teaching experience.",
          "contactDetails": {
            "phone": "+9876543210",
            "email": "jane.doe@example.com"
          },
          "skills": ["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"],
          "schedule": {
            "monday": "10:00 AM - 4:00 PM",
            "tuesday": "9:00 AM - 10:00 AM",
            "wednesday": "11:00 AM - 3:00 PM",
            "thursday": "10:00 AM - 11:00 AM",
            "friday": "9:00 AM - 10:00 AM"
          },
          "personalDetails": {
            "name": "Jane Doe",
            "title": "Grade 2 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.16.jpeg'),
          }
        },



        {
          "Id" : 3,
          "degree": "Bachelor's Degree in Science",
          "experience": 6,
          "hobbies": ["gardening", "cooking", "reading"],
          "myCourses": [
            {
              "title": "Grade 3 Science",
              "description": "A course on science for grade 3 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a passionate science teacher with 6 years of experience.",
          "contactDetails": {
            "phone": "+5555555555",
            "email": "bob.smith@example.com"
          },
          "skills":  ["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"]
          ,
          "schedule": {
            "monday": "11:00 AM -3:00 PM",
            "tuesday": "10:00 AM - 11:00 AM",
            "wednesday": "9:00 AM - 4:00 PM",
            "thursday": "11:00 AM - 12:00 PM",
            "friday": "10:00 AM - 11:00 AM"
          },
          "personalDetails": {
            "name": "Bob Smith",
            "title": "Grade 3 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.44.jpeg'),
          }
        },


        {
          "Id" : 4,
          "degree": "Bachelor's Degree in Language Arts",
          "experience": 8,
          "hobbies": ["writing", "reading", "traveling"],
          "myCourses": [
            {
              "title": "Grade 4 English",
              "description": "A course on English language and literature for grade 4 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a dedicated and passionate language arts teacher with 8 years of experience.",
          "contactDetails": {
            "phone": "+6666666666",
            "email": "alice.johnson@example.com"
          },
          "skills":["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"]
          ,
          "schedule": {
            "monday": "9:00 AM - 3:00 PM",
            "tuesday": "11:00 AM - 12:00 PM",
            "wednesday": "10:00 AM - 4:00 PM",
            "thursday": "9:00 AM - 10:00 AM",
            "friday": "11:00 AM - 12:00 PM"
          },
          personalDetails: {
            "name": "Alice Johnson",
            "title": "Grade 4 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.50.09.jpeg'),
          }
        }
      ]
    }

  }
}
</script>

<style scoped>



.banner {
  background: url(../assets/images/IMG-20241009-WA0001.jpg) center no-repeat;
  background-size: cover;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 36px;
  text-align: left;

}



</style>