<template>


  <div class="footer-area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-info-container text-center my-1">
            <div class="footer-logo">
              <a href="#">
                <img src="../assets/images/logo.png" alt="" height="65" width="65" />

              </a>
            </div>
            <div class="footer-info mb-3">
              <span
                ><i class="fa fa-map-marker"></i>
                    <span>19715 New Cerney Park, <br> <br>
Saturday Retreat, Harare south

                    </span>
                </span
                >
              <span
              ><i class="fa fa-envelope"></i>
              <span>
                info@southmews <br><br>
                <span class="ms-3 ">

                          </span>
              </span>
              </span
              >
              <span><i class="fa fa-phone"></i>
                          <span>+263 77 692 0588 | <br><br>
                            <span class="ms-3 ">
                            +263 77 640 3559
                          </span> </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-widget-container ">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-12">
            <div class="single-footer-widget">
              <h3 class="mb-0" style="text-align: left">Southmews High</h3>

              <p>
                "Nurturing minds. Empowering futures.<br />
                We provide a dynamic and inclusive learning environment.<br />
                Our dedicated educators foster critical thinking, creativity, and
                character development. With personalized learning and a supportive
                community, we prepare students to become lifelong learners and
                responsible global citizens. Join us in shaping the leaders of
                tomorrow."
              </p>

            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-4">
            <div class="single-footer-widget">
              <h4>Our School</h4>
              <ul class="footer-widget-list">
                <li><a href="#">About Us</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Contact</a></li>
                <li><a href="#">Become a Teacher</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-4">
            <div class="single-footer-widget">
              <h4>Links</h4>
              <ul class="footer-widget-list">
                <li><a href="#">Courses</a></li>
                <li><a href="#">Events</a></li>
                <li><a href="#">Gallery</a></li>
                <li><a href="#">FAQs</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-4">
            <div class="single-footer-widget">
              <h4>Support</h4>
              <ul class="footer-widget-list">
                <li><a href="#">Documentation</a></li>
                <li><a href="#">Forums</a></li>
                <li><a href="#">Language Packs</a></li>
                <li><a href="#">Release Status</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="subscribe-container">
              <p>
                Subscribe now and receive weekly newsletter with educational
                materials, new courses, interesting posts, popular books and
                much more!
              </p>
              <form action="#">
                <div class="subscribe-form">
                  <input
                      type="email"
                      name="email"
                      placeholder="Your email here"
                  />
                  <button type="submit"><i class="fa fa-send"></i></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-container">
            <div class="row">
              <div class="col-lg-6">
                  <span
                  >
                      &copy; 2025 <router-link to="">SouthMews college</router-link>. All
                    rights reserved</span
                  >

              </div>
              <div class="col-lg-6">
                <div class="social-links">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-pinterest-p"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {services} from "@/services/axios";

export default  {
  name:'footer',
    data (){
    return {
          subscriber:{
            firstname:'',
            lastname:'',
            email:'',
          },



      emailError:'',
      firstnameError:'',
      lastnameError:''
    }
    },
  methods: {
    subscribe: async function () {
      this.clearErrors();
      let isValid = true;


      if (this.subscriber.firstname=== '') {
        this.showError('firstname', 'Please enter your name.');
        isValid = false;
      }


      if (this.subscriber.lastname=== '') {
        this.showError('lastname', 'Please enter your last Name.');
        isValid = false;
      }


      if (this.subscriber.email==='') {
        this.showError('email', 'Please enter email address.');
        isValid = false;
      }

      else if (!this.isValidEmail(this.subscriber.email)) {
        this.showError('email', 'Please enter a valid email address.');
        isValid = false;
      }

      if (isValid) {
        try {
          let response= await services.saveSubscriber(this.subscriber)
          if (response){

            this.$router.push('/')
          }
          else{
            // this.$router.push('/')
          }

        }
        catch (error){

          console.log(error)

        }

      }
    },
    showError(field, message) {
      this[`${field}Error`] = message;
    },
    clearErrors() {

      this.firstnameError = '';
      this.lastnameError = '';
      this.emailError = '';


    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  }
}


</script>