document.addEventListener('DOMContentLoaded', function () {
    "use strict";

    // Menu Stick
    const sticker = document.querySelector('.sticker');
    if (sticker) {
        let isStuck = false;

        window.addEventListener('scroll', function () {
            const windScr = window.scrollY;
            const windowWidth = window.innerWidth;

            if (windowWidth >= 10) {
                if (windScr === 0) {
                    if (isStuck) {
                        isStuck = false;
                        sticker.style.opacity = 0;
                        sticker.classList.remove('stick', 'slideDown');
                        setTimeout(() => {
                            sticker.style.opacity = 1;
                        }, 300);
                    }
                } else {
                    if (!isStuck) {
                        isStuck = true;
                        sticker.style.opacity = 0;
                        setTimeout(() => {
                            sticker.classList.add('stick', 'slideDown');
                            sticker.style.opacity = 1;
                        }, 300);
                    }
                }
            }
        });
    }

    // Toggle Search
    // const searchToggle = document.querySelector('.header-search');
    // const searchBox = document.querySelector('.search');
    // searchToggle.addEventListener('click', function () {
    //     searchBox.classList.toggle('open');
    //     searchToggle.querySelector('i.fa-search').classList.toggle('fa-close');
    //     return false;
    // });

    // const searchFormSubmit = document.querySelector('#search-form input[type=submit]');
    // searchFormSubmit.addEventListener('click', function () {
    //     searchBox.classList.toggle('open');
    //     return true;
    // });

    // document.addEventListener('click', function (event) {
    //     const target = event.target;
    //     if (!target.closest('.header-search') && !target.closest('.search')) {
    //         searchBox.classList.remove('open');
    //         searchToggle.querySelector('i.fa-search').classList.remove('fa-close');
    //     }
    // });
})