<template>
  <div>
<p>chat coming soon ....!</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>