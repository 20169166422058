<template>

  <div class="row">
    <div class="col-md-12">
      <div class="banner mt-2">
        <h3 class="box-heading">Events</h3>
      </div>
    </div>
  </div>

  <div class="blog-fullwidth-area section-padding blog-two">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-3" v-for="event in events" :key="event.eventId">
          <div class="single-blog-item overlay-hover">
            <div class="single-blog-image">
              <div class="overlay-effect">
                <router-link :to="`eventDetails/${event.eventId}`">
                  <img :src="event.image" alt="event" />
                  <span class="class-date">{{ event.date.split(',')[0].split( ' ')[0].slice(0,3) }} {{ event.date.split(',')[0].split( ' ')[1] }}<span>{{ event.date.split(',')[1] }}</span></span>
                </router-link>
              </div>
            </div>
            <div class="single-blog-text">
              <h4>
                <router-link :to="`eventDetails/${event.eventId}`">{{ event.title }}</router-link>
              </h4>
              <div class="blog-date">
                <span><i class="fa fa-calendar"></i>{{ event.date}}</span>
              </div>
              <p>
                {{ event.description.slice(0, 400) + "...." }}
              </p>
              <router-link :to="`eventDetails/${event.eventId}`">Read more.</router-link>
            </div>
          </div>
        </div>


      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="pagination-content">
            <div class="pagination-button">
              <ul class="pagination">
                <li class="current"><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li>
                  <a href="#"><i class="fa fa-caret-right"></i></a>
                </li>
              </ul>
              <span>Page:</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import spinner from "@/components/spinner.vue";
import {services} from "@/services/axios";

export default {
  components: {
    spinner
  },
  data() {
    return {
      events: [
        {
          "eventId": 1,
          "description": "A fascinating annual tech conference showcasing the latest innovations.",
          "category": "Technology",
          "dateCreated": "June 15, 2023",
          "date": "September 10, 2023",
          "image": require('@/assets/images/school2.jpg'),
          "status": "Upcoming",
          "subCategory": "Software",
          "title": "Tech Expo 2023"
        },
        {
          "eventId": 2,
          "description": "A vibrant music festival featuring both local and international artists.",
          "category": "Entertainment",
          "dateCreated": "April 20, 2023",
          "date": "July 25, 2023",
          "image": require('@/assets/images/studentpic2.jpg'),
          "status": "Ongoing",
          "subCategory": "Music",
          "title": "Summer Beats Festival"
        },
        {
          "eventId": 3,
          "description": "An educational symposium discussing the latest advancements in renewable energy solutions.",
          "category": "Environment",
          "dateCreated": "February 1, 2023",
          "date": "November 12, 2023",
          "image": require('@/assets/images/teacher.jpg'),
          "status": "Upcoming",
          "subCategory": "Energy",
          "title": "Renewable Energy Symposium"
        },
        {
          "eventId": 4,
          "description": "A culinary extravaganza showcasing the diverse flavors of the region.",
          "category": "Food",
          "dateCreated": "July 1, 2023",
          "date": "August 18, 2023",
          "image": require('@/assets/images/contactus2.webp'),
          "status": "Upcoming",
          "subCategory": "Cuisine",
          "title": "Flavor Fest 2023"
        },
        {
          "eventId": 5,
          "description": "An art exhibition featuring the works of renowned local and international artists.",
          "category": "Arts",
          "dateCreated": "March 15, 2023",
          "date": "June 30, 2023",
          "image": require('@/assets/images/background.jpg'),
          "status": "Completed",
          "subCategory": "Visual Arts",
          "title": "Artistic Expressions"
        }
      ],
      loading: false,
      error: ""
    }
  },
  mounted: async function () {
//     try {
//       this.loading=true
//       let response=await services.getAllEvents()
//       this.events=response.data
//       this.loading=false
//     }
//     catch (e) {
//       this.loading=true
//
// this.error=e
//       this.loading=false
//     }


  }

};
</script>
