<template>
  <div class="carousel" ref="carousel">
    <div class="list">
      <div class="item" v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" :alt="slide.alt">
        <div class="content">
          <div class="author">Southmews Collage</div>
          <div class="topic title">{{ slide.title }}</div>
          <div class="topic">{{ slide.topic }}</div>
          <div class="des">{{ slide.des }}</div>
          <div class="buttons">
            <router-link class="btn btn-success button" :to="slide.link">{{ slide.buttonText }}</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="thumbnail">
      <div class="item" v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" :alt="slide.alt">
        <div class="content">
          <div class="title">{{ slide.title }}</div>
        </div>
      </div>
    </div>

    <div class="arrows">
      <button ref="prevButton" id="prev"><</button>
      <button ref="nextButton" id="next">></button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        {
          image: require('@/assets/images/sports.jpg'),
          alt: 'school2',
          title: "Unlock Your Potential",
          des: "Explore exciting learning opportunities with our dynamic programs. Our dedicated faculty and innovative curriculum cater to diverse interests and learning styles, ensuring that every student finds their path to success. Discover new passions and develop valuable skills that prepare you for the future.",
          link: "#programs",
          buttonText: "Enroll Now"
        },
        {
          image: require('@/assets/images/IMG-20241009-WA0052.jpg'),
          alt: 'image2',
          title: "Achieve Academic Success",
          des: "Utilize top-notch resources for studying, projects, and exam prep. Our state-of-the-art library, online databases, and tutoring services are designed to support your academic journey. With personalized guidance and extensive materials, you can excel in your studies and achieve your academic goals.",
          link: "#resources",
          buttonText: "Discover"
        },
        {
          image: require('@/assets/images/IMG-20241009-WA0067.jpg'),
          alt: 'image3',
          title: "Stay Informed",
          des: "Catch up on the latest school news, events, and tips on our blog. Stay connected with our vibrant school community through updates on activities, achievements, and important announcements. Our blog also features articles on study tips, student life, and interviews with faculty and alumni.",
          link: "#blog",
          buttonText: "Read More"
        },
        {
          image: require('@/assets/images/students.jpg'),
          alt: 'image4',
          title: "Get Involved",
          des: "Join clubs and activities that match your interests and passions. From sports teams to academic clubs, our school offers a wide range of extracurricular options that encourage teamwork, leadership, and personal growth. Get involved and make lasting memories while developing your skills and friendships.",
          link: "#activities",
          buttonText: "Explore"
        },
        {
          image: require('@/assets/images/corridor.jpg'),
          alt: 'image5',
          title: "Shop for School Gear",
          des: "Find essential supplies and spirit gear to show your school pride. Our school store offers everything from textbooks to branded apparel, ensuring you're well-equipped for the school year. Show off your school spirit with our exclusive merchandise and feel connected to the community.",
          link: "#shop",
          buttonText: "Shop Now"
        }
      ],
      runNextAuto: null,
      runTimeOut: null
    };
  },
  mounted() {
    this.initializeSlider();
  },
  beforeDestroy() {
    this.cleanupSlider();
  },
  methods: {
    initializeSlider() {
      const nextDom = this.$refs.nextButton;
      const prevDom = this.$refs.prevButton;
      const carouselDom = this.$refs.carousel;
      const SliderDom = carouselDom.querySelector('.list');
      const thumbnailBorderDom = carouselDom.querySelector('.thumbnail');
      const thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item');

      if (!nextDom || !prevDom || !carouselDom || !SliderDom || thumbnailItemsDom.length === 0) {
        console.error("Carousel elements not found or thumbnail items are empty.");
        return; // Exit if elements are not found or empty
      }

      thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);

      nextDom.onclick = () => {
        this.showSlider('next', SliderDom, thumbnailBorderDom);
      };

      prevDom.onclick = () => {
        this.showSlider('prev', SliderDom, thumbnailBorderDom);
      };

      this.runNextAuto = setTimeout(() => {
        if (nextDom) {
          nextDom.click();
        } else {
          console.error("Next button not found for auto-click.");
        }
      }, 7000);
    },

    cleanupSlider() {
      clearTimeout(this.runNextAuto);
      clearTimeout(this.runTimeOut);
    },

    showSlider(type, SliderDom, thumbnailBorderDom) {
      const SliderItemsDom = SliderDom.querySelectorAll('.item');
      const thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item');

      if (SliderItemsDom.length === 0 || thumbnailItemsDom.length === 0) {
        console.error("No items to show in the slider.");
        return; // Exit if there are no items
      }

      const carouselDom = this.$refs.carousel;

      if (!carouselDom) {
        console.error("Carousel DOM reference is null.");
        return; // Ensure carouselDom is valid
      }

      if (type === 'next') {
        SliderDom.appendChild(SliderItemsDom[0]);
        thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
        carouselDom.classList.add('next');
      } else {
        SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
        thumbnailBorderDom.prepend(thumbnailItemsDom[thumbnailItemsDom.length - 1]);
        carouselDom.classList.add('prev');
      }

      clearTimeout(this.runTimeOut);
      this.runTimeOut = setTimeout(() => {
        carouselDom.classList.remove('next');
        carouselDom.classList.remove('prev');
      }, 3000);

      clearTimeout(this.runNextAuto);
      this.runNextAuto = setTimeout(() => {
        if (this.$refs.nextButton) { // Check if next button exists
          this.$refs.nextButton.click();
        } else {
          console.error("Next button not found during auto-click.");
        }
      }, 7000);
    }
  }
}
</script>


<style  scoped>



/* carousel */
.carousel{
  height: 90vh;
  margin-top: -50px;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.carousel .list .item{
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
}
.carousel .list .item img{
  width: 100%;
  height: 100%;
object-fit: cover;
}
.carousel .list .item .content{
  position: absolute;
  top:8%;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 3px 40px rgba(0, 0, 0, 0.8);
  padding: 5%;;
  border-radius: 10px;
  transition: background 0.3s ease;

}
.carousel .list .item .author{
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 12px;



}
.carousel .list .item .title,
.carousel .list .item .topic{
  font-size: 48px;
  font-weight: bold;
  line-height: 1.3em;
  color: #f1683a;
}
.carousel .list .item .des{
 background-color: rgba(152, 151, 151, 0.8);
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 12px;
  padding: 10px;
  max-width: 50%;
  border-radius: 10px;

}
.carousel .list .item .buttons{
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-template-rows: 40px;
  gap: 5px;
  margin-top: 20px;
}
.carousel .list .item .buttons .button{
  border: none;
  background-color: #0f1365;
  letter-spacing: 2px;
  font-weight: 500;
}
.carousel .list .item .buttons button:nth-child(2){
  background-color: transparent;
  border: 1px solid #fff;
  color: #eee;
}
/* thumbail */
.thumbnail{
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: max-content;
  z-index: 100;
  display: flex;
  gap: 20px;

}
.thumbnail .item{
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  position: relative;
}
.thumbnail .item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.thumbnail .item .content{
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}
.thumbnail .item .content .title{
  font-weight: 500;
}
/* arrows */
.arrows{
  position: absolute;
  top: 80%;
  right: 52%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.arrows button{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1a1919;
  border: none;
  color: rgb(255, 255, 255);
  font-family: monospace;
  font-weight: bold;
  transition: .5s;
}
.arrows button:hover{
  background-color: #fff;
  color: #000;
}

/* animation */
.carousel .list .item:nth-child(1){
  z-index: 1;
}

/* animation text in first item */

.carousel .list .item:nth-child(1) .content .author,
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons
{
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent .5s .5s linear 1 forwards;
}
@keyframes showContent{
  to{
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
.carousel .list .item:nth-child(1) .content .title{
  animation-delay: 1.2s!important;
}
.carousel .list .item:nth-child(1) .content .topic{
  animation-delay: 1.4s!important;
}
.carousel .list .item:nth-child(1) .content .des{
  animation-delay: 1.6s!important;
}
.carousel .list .item:nth-child(1) .content .buttons{
  animation-delay: 1.8s!important;
}
/* create animation when next click */
.carousel.next .list .item:nth-child(1) img{
  width: 150px;
  height: 220px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  border-radius: 30px;
  animation: showImage .5s linear 1 forwards;
}
@keyframes showImage{
  to{
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.carousel.next .thumbnail .item:nth-last-child(1){
  overflow: hidden;
  animation: showThumbnail .5s linear 1 forwards;
}
.carousel.prev .list .item img{
  z-index: 100;
}
@keyframes showThumbnail{
  from{
    width: 0;
    opacity: 0;
  }
}
.carousel.next .thumbnail{
  animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext{
  from{
    transform: translateX(150px);
  }
}

/* running time */




@keyframes runningTime{
  from{ width: 100%}
  to{width: 0}
}


/* prev click */

.carousel.prev .list .item:nth-child(2){
  z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img{
  animation: outFrame 0.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}
@keyframes outFrame{
  to{
    width: 120px;
    height: 120px;
    bottom: 50px;
    left: 50%;
    border-radius: 20px;
  }
}

.carousel.prev .thumbnail .item:nth-child(1){
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail .5s linear 1 forwards;
}
.carousel.next .arrows button,
.carousel.prev .arrows button{
  pointer-events: none;
}
.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons
{
  animation: contentOut .5s linear 1ms forwards!important;
}

@keyframes contentOut{
  to{
    transform: translateY(-150px);
    filter: blur(20px);
    opacity: 0;
  }
}
@media screen and (max-width: 678px) {
  .carousel .list .item .content{
    padding-right: 0;
  }
  .carousel .list .item .content .title{
    font-size: 20px;
  }
}
@media screen and (max-width: 425px) {


  /* carousel */
  .carousel{
    height: 65vh;

  }


  .carousel .list .item .author{
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 0;


  }
  .carousel .list .item .content .title,
  .carousel .list .item .content .title{

    font-weight: bold;
    line-height:1.1em;
    color: #f1683a;
    font-size: 28px;
  }
  .carousel .list .item .des{
    background-color: rgba(152, 151, 151, 0.7);
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 12px;
    padding: 5px;
    max-width: 90%;


  }
  .carousel .list .item .buttons{
    display: grid;
    grid-template-columns: repeat(2, 130px);
    margin-top: 10px;
  }
  .carousel .list .item .buttons .button{
    letter-spacing: 1px;

  }
  .carousel .list .item .buttons button:nth-child(2){
    background-color: transparent;
    border: 1px solid #fff;
    color: #eee;
  }
  /* thumbail */
  .thumbnail{
    bottom: 25px;
    left: 50%;
    z-index: 100;
    display: flex;
    gap: 10px;

  }
  .thumbnail .item{
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    position: relative;
  }
  .thumbnail .item img{

    border-radius: 10px;
  }
  .thumbnail .item .content{
    display: none;
  }
  .thumbnail .item .content .title{
    font-weight: 500;
  }
  /* arrows */
  .arrows{
    display: none;
  }




}

</style>
