window.wizard;

document.addEventListener('DOMContentLoaded', function () {
  class BootstrapWizard {
    constructor(element) {
      this.element = element;
      this.currentTab = this.element.querySelector('li.active');
      this.init();
    }

    init() {
      this.bindClickEvents();
      this.updateNavigationButtons();
    }

    bindClickEvents() {
      const tabs = this.getTabs();
      tabs.forEach((tab, index) => {
        tab.querySelector('a[data-toggle="tab"]').addEventListener('click', (event) => this.onTabClick(event, index));
      });

      this.element.querySelector('.next').addEventListener('click', () => this.next());
      this.element.querySelector('.previous').addEventListener('click', () => this.previous());
    }

    onTabClick(event, index) {
      const clickedTab = event.target.parentElement;
      if (clickedTab.classList.contains('disabled')) {
        event.preventDefault();
        return false;
      }

      if (this.currentTab) {
        this.currentTab.classList.remove('active');
      }

      this.currentTab = clickedTab;
      this.currentTab.classList.add('active');
      this.updateNavigationButtons();
    }

    updateNavigationButtons() {
      const tabs = this.getTabs();
      const currentIndex = this.currentIndex();

      document.querySelector('.previous').classList.toggle('disabled', currentIndex === 0);
      document.querySelector('.next').classList.toggle('disabled', currentIndex === tabs.length - 1);
      this.updateStepIcons(currentIndex);
    }

    currentIndex() {
      const tabs = this.getTabs();
      return Array.from(tabs).indexOf(this.currentTab);
    }

    getTabs() {
      return this.element.querySelectorAll('.nav-item')
    }

    next() {
      const nextIndex = this.currentIndex() + 1;
      if (nextIndex < this.getTabs().length) {
        this.show(nextIndex);
      }
    }

    previous() {
      const prevIndex = this.currentIndex() - 1;
      if (prevIndex >= 0) {
        this.show(prevIndex);
      }
    }

    show(index) {
      const tabs = this.getTabs();
      if (tabs[index]) {
        // Activate the clicked tab
        tabs[index].querySelector('a[data-toggle="tab"]').click();

        // Show the corresponding tab content
        const tabPanes = this.element.querySelectorAll('.tab-pane');
        tabPanes.forEach((pane) => {
          pane.style.display = 'none'; // Hide all panes
        });

        const newTabContent = this.element.querySelector(`#${tabs[index].querySelector('a').getAttribute('href').substring(1)}`);
        if (newTabContent) {
          newTabContent.style.display = 'block'; // Show the active pane
        }
      }
    }

    updateStepIcons(index) {
      const tabs = this.getTabs();
      tabs.forEach((tab, i) => {
        const icon = tab.querySelector('.step-icon');
        if (i < index) {
          icon.classList.add('text-success'); // Change the color to indicate completed
        } else {
          icon.classList.remove('text-success'); // Reset the color
        }
      });
    }
  }

  const wizardElement = document.querySelector('#progress-wizard');
  window.wizard = new BootstrapWizard(wizardElement);
});