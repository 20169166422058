<template>
  <navbar/>
  <router-view/>
  <footerView/>
</template>

<script>
import navbar from "./components/navbar.vue";
import naigation from "./components/Navigation.vue";
import footerView from "./components/footerView.vue";

export default {
  name: "App",
  components: {
    navbar,
    footerView,
  },
};
</script>

<style>

#app {
  font-family: sans-serif, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  //margin-top: 4.5rem;
  font-size: 13px;
}


</style>
