<template>
  <div class="breadcrumb-banner-area">

    <div class="row">
      <div class="col-md-12">
        <div class="banner">
          <h1 class=" box-heading">Teachers</h1>
        </div>
      </div>
    </div>



  </div>
  <div class="teacher-fullwidth-area section-padding">
    <div class="container">
      <div class="row d-flex">



            <div class="col-lg-3 col-md-3 col-sm-3 col-12"  v-for="teacher in teachers" :key="teacher">

              <div class="single-teachers-column text-center">
                <div class="teachers-image-column">
                  <router-link :to="`teacher/${teacher.Id}`">
                    <img :src="teacher.personalDetails.profilePicture" alt="" />
                    <span class="image-hover">
                                        <span><i class="fa fa-edit"></i>View Profile</span>
                                    </span>
                  </router-link>
                </div>
                <div class="teacher-column-carousel-text">
                  <h4>{{ teacher.personalDetails.name }}</h4>
                  <span>{{ teacher.personalDetails.title }}, {{ teacher.personalDetails.college}}</span>
                  <p>{{
                      teacher.description
                    }}</p>
                  <div class="social-links">
                    <router-link to="#"><i class="fa fa-facebook"></i></router-link>
                    <router-link to="#"><i class="fa fa-google-plus"></i></router-link>
                    <router-link to="#"><i class="fa fa-twitter"></i></router-link>
                    <router-link to="#"><i class="fa fa-instagram"></i></router-link>
                  </div>
                </div>
              </div>
            </div>



      </div>

      <div class="col-md-12">
        <div class="button text-center">
         <router-link to="#" class="button-default button-yellow"><i class="fa fa-refresh"></i>Load More</router-link>
        </div>
      </div>
    </div>
  </div>
   
</template>
<script>
export default {
  name: 'teachers',
  data() {
    return {
      teachers:[

        {
          "Id" : 1,
          "degree": "Bachelor's Degree in Elementary Education",
          "experience": 5,
          "hobbies": ["reading", "hiking", "painting"],
          "myCourses": [
            {
              "title": "Grade 1 English",
              "description": "A course on English language and literature for grade 1 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a passionate and dedicated teacher with 5 years of experience.",
          "contactDetails": {
            "phone": "+1234567890",
            "email": "john.doe@example.com"
          },
          "skills": ["Microsoft Office", "Google Classroom",
            "communication", "teamwork", "problem-solving"],

          "schedule": {
            "monday": "9:00 AM - 3:00 PM",
            "tuesday": "10:00 AM - 11:00 AM",
            "wednesday": "9:00 AM - 4:00 PM",
            "thursday": "11:00 AM - 12:00 PM",
            "friday": "10:00 AM - 11:00 AM"
          },
          personalDetails: {
            name: "John Doe",
            title: "Grade 1 Teacher",
            college: "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.48.51.jpeg'),          }
        },



        {
          "Id" : 2,
          "degree": "Bachelor's Degree in Mathematics",
          "experience": 3,
          "hobbies": ["playing chess", "reading", "coding"],
          "myCourses": [
            {
              "title": "Grade 2 Mathematics",
              "description": "A course on mathematics for grade 2 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a math enthusiast with 3 years of teaching experience.",
          "contactDetails": {
            "phone": "+9876543210",
            "email": "jane.doe@example.com"
          },
          "skills": ["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"],
          "schedule": {
            "monday": "10:00 AM - 4:00 PM",
            "tuesday": "9:00 AM - 10:00 AM",
            "wednesday": "11:00 AM - 3:00 PM",
            "thursday": "10:00 AM - 11:00 AM",
            "friday": "9:00 AM - 10:00 AM"
          },
          "personalDetails": {
            "name": "Jane Doe",
            "title": "Grade 2 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.16.jpeg'),
          }
        },



        {
          "Id" : 3,
          "degree": "Bachelor's Degree in Science",
          "experience": 6,
          "hobbies": ["gardening", "cooking", "reading"],
          "myCourses": [
            {
              "title": "Grade 3 Science",
              "description": "A course on science for grade 3 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a passionate science teacher with 6 years of experience.",
          "contactDetails": {
            "phone": "+5555555555",
            "email": "bob.smith@example.com"
          },
          "skills":  ["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"]
          ,
          "schedule": {
            "monday": "11:00 AM -3:00 PM",
            "tuesday": "10:00 AM - 11:00 AM",
            "wednesday": "9:00 AM - 4:00 PM",
            "thursday": "11:00 AM - 12:00 PM",
            "friday": "10:00 AM - 11:00 AM"
          },
          "personalDetails": {
            "name": "Bob Smith",
            "title": "Grade 3 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.44.jpeg'),
          }
        },


        {
          "Id" : 4,
          "degree": "Bachelor's Degree in Language Arts",
          "experience": 8,
          "hobbies": ["writing", "reading", "traveling"],
          "myCourses": [
            {
              "title": "Grade 4 English",
              "description": "A course on English language and literature for grade 4 students",
              "duration": "1 year"
            }
          ],
          "description": "I am a dedicated and passionate language arts teacher with 8 years of experience.",
          "contactDetails": {
            "phone": "+6666666666",
            "email": "alice.johnson@example.com"
          },
          "skills":["Microsoft Office", "Google Classroom","communication", "teamwork", "problem-solving"]
          ,
          "schedule": {
            "monday": "9:00 AM - 3:00 PM",
            "tuesday": "11:00 AM - 12:00 PM",
            "wednesday": "10:00 AM - 4:00 PM",
            "thursday": "9:00 AM - 10:00 AM",
            "friday": "11:00 AM - 12:00 PM"
          },
          personalDetails: {
            "name": "Alice Johnson",
            "title": "Grade 4 Teacher",
            "college": "Example College",
            "profilePicture": require('@/assets/images/WhatsApp Image 2024-10-09 at 11.50.09.jpeg'),
          }
        }
      ]
    }

  }
   }


</script>

<style scoped>


</style>